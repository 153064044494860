import React, { useState } from 'react'
import style from './style.module.scss'
import { isNumeric, isNumericAndNot, isText } from 'newui/Utils/uiUtil';
import * as manageGames from 'newui/Services/Backend/manageGames';
import TextField from 'newui/Generic/Inputs/TextField';
import Checkbox from 'newui/Generic/Inputs/Checkbox';
import ComboBox from 'newui/Generic/Inputs/ComboBox';
import Button from 'newui/Generic/Inputs/Button';
import ImageLoader from 'newui/Generic/Inputs/ImageLoader';
import DatePicker from 'newui/Generic/Inputs/DatePicker';


function GameEditor(props) {
  const [id] = useState(props.game ? props.game.id : -1);
  const [active, setActive] = useState(props.game ? props.game.active : 0)
  const [category_id, setCategory_id] = useState(props.game ? props.game.category_id : (props.currentCategory ? props.currentCategory.id : -1))


  const [game_id, setGame_id] = useState(props.game ? props.game.game_id : '')
  const [game_image_url, setGame_image_url] = useState(props.game ? props.game.game_image_url : '')
  const [isNew, setIsNew] = useState(props.game ? props.game.isNew : 0)
  const [needAccount, setNeedAccount] = useState(props.game ? props.game.needAccountToPlay : 0)
  const [name, setName] = useState(props.game ? props.game.name : '')
  const [newUntilDate, setNewUntilDate] = useState((props.game && props.game.isNewDate) ? formatDate(new Date(Date.parse(props.game.isNewDate) + 43200000)) : '')
  const [activationDate, setActivationDate] = useState((props.game && props.game.activationDate) ? formatDate(new Date(Date.parse(props.game.activationDate) + 43200000)) : '')
  const [provider_id, setProvider_id] = useState(props.game ? props.game.provider_id : (props.currentProvider ? props.currentProvider.id : -1))
  const [subType, setSubType] = useState(props.game ? props.game.subType : (props.currentSubType ? props.currentSubType.id : -1))
  const [typeId, setTypeId] = useState(props.game ? props.game.typeId : (props.currentType ? props.currentType.id : -1))
  const [error, setError] = useState('');
  const [forceReload, setForceReload] = useState(Math.random());

  function validate(clone) {
    return isText(clone.name) && isNumericAndNot(clone.category_id, -1) && isNumeric(clone.game_id) && isNumericAndNot(clone.provider_id, -1);
  }

  function onDragOverImage(ev) {
    ev.preventDefault();
  }

  function handleFileUpload(event, game) {
    event.preventDefault();

    if (event.dataTransfer && event.dataTransfer.items) {
      if (event.dataTransfer.items.length > 1) {
        console.log('More than one file');
        return;
      }

      if (event.dataTransfer.items[0].kind === 'file') {
        const file = event.dataTransfer.items[0];
        manageGames.uploadGameImageFile(file.getAsFile(), uploadSuccess, uploadFailure)
      }
    } else if (event.target.files) {
      if (event.target.files.length > 1) {
        console.log('More than one file');
        return;
      }

      const file = event.target.files[0];
      manageGames.uploadGameImageFile(file, uploadSuccess, uploadFailure)
    }

  }

  function uploadSuccess(data) {
    setGame_image_url(data.url);
    setForceReload(Math.random());
  }

  function uploadFailure(data) {

  }

  function onSave(newGameImageFile) {
    var toClone = props.game ? props.game : {}
    var clone = JSON.parse(JSON.stringify(toClone))

    clone.id = id;
    clone.active = active;
    clone.category_id = category_id + '';
    clone.game_id = game_id;
    clone.game_image_url = game_image_url;
    clone.isNew = isNew;
    clone.isNewUntil = newUntilDate;
    clone.activationDate = activationDate;
    if (active) {
      clone.activationDate = null;
    }

    clone.needAccountToPlay = needAccount;
    clone.name = name;
    clone.provider_id = provider_id + '';
    clone.subType = subType + '';

    if (validate(clone)) {
      props.closeModal();

      var action = 'MODIFY';
      if (props.index === -1) {
        action = 'ADD'
      }

      props.callback(clone, props.index, action);
    } else {
      setError('Validation Error');
    }
  }

  function onDelete() {
    props.closeModal();
    props.callback(props.game, props.index, 'DELETE_GAME');
  }

  function toggleActive(value) {
    if (value === false) {
      setActive(0)
    } else {
      setActive(1)
    }
  }

  function formatDate(theDate) {
    const yyyy = theDate.getFullYear();
    let mm = theDate.getMonth() + 1; // Months start at 0!
    let dd = theDate.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return dd + '/' + mm + '/' + yyyy;
  }

  function toggleNew(value) {
    if (value === false) {
      setIsNew(0)
    } else {
      setIsNew(1)
      var theUntilDate = new Date();
      theUntilDate.setDate(theUntilDate.getDate() + 14);
      setNewUntilDate(formatDate(theUntilDate));

    }
  }

  function toggleNeedAccount(value) {
    if (value === false) {
      setNeedAccount(0)
    } else {
      setNeedAccount(1)
    }
  }


  try {

    if (props.subTypes) {
      return (
        <div>
          <div className='topModal_pops topModal_title'>
            <h4 className='topModal_rg-heads'>
              Game Editor
            </h4>
          </div>
          <div className={style.twoColGrid}>
            <div>
              <div className={style.centerize} onDragOver={(e) => onDragOverImage(e)} onDrop={(e) => handleFileUpload(e, props.game)}>
                <ImageLoader width='200px' image={game_image_url} />
              </div>
              <div className={style.spacer}>
                <div className={style.spacer2}>
                  <Button width='250px' colorStyle='light' value={'Select Image'} onClick={() => document.getElementById('uploadMyFile').click()} />
                  <input className={style.fileInput} type="file" id="uploadMyFile" name="myfile" onChange={(e) => handleFileUpload(e, props.game)} />
                </div>

                <div className={style.twoColGrid50a}>
                  <div className={style.vertCenter}><Checkbox label={'Active'} checked={(active === 1)} onClick={toggleActive} /></div>
                  <div className={style.vertCenter}>
                    {(active === 0) &&
                      <DatePicker refresh={activationDate} hint='Active Date' values={[activationDate]} onChange={setActivationDate} />
                    }
                  </div>
                </div>

                <div className={style.twoColGrid50a}>
                  <div className={style.vertCenter}><Checkbox label={'New'} checked={(isNew === 1)} onClick={toggleNew} /></div>
                  <div className={style.vertCenter}>
                    {(isNew === 1) &&
                      <DatePicker refresh={newUntilDate} hint='New Until' values={[newUntilDate]} onChange={setNewUntilDate} />
                    }
                  </div>
                </div>
                <div><Checkbox label={'Need Account To Play'} checked={(needAccount === 1)} onClick={toggleNeedAccount} /></div>
              </div>
              <div className={style.spacer}>
                <div className={style.twoColGrid50}>
                  <div><Button width='100px' colorStyle='dark' value={'Save'} onClick={onSave} /></div>
                  {(props.index > -1) &&
                    <div><Button width='100px' colorStyle='light' value={'Delete'} onClick={onDelete} /></div>
                  }
                </div>
              </div>
            </div>
            <div>
              <div className={style.bottomPad}><TextField autofocus='true' values={[name]} label={'Name:'} onChange={setName} /></div>
              <div className={style.bottomPad}><TextField type='digit' values={[game_id]} label={'Game Id:'} onChange={setGame_id} /></div>
              <div className={style.bottomPad}><TextField key={forceReload} values={[game_image_url]} label={'Game Image Url:'} onChange={setGame_image_url} /></div>
              <div className={style.bottomPad}><ComboBox label={'Provider:'} onChange={setProvider_id} values={[provider_id]} valueList={{ list: props.providers, key: 'id', value: 'name' }} selectLabel={{ value: '-1', label: 'Select One' }} /></div>
              <div className={style.bottomPad}><ComboBox label={'Category:'} onChange={setCategory_id} values={[category_id]} valueList={{ list: props.categories, key: 'id', value: 'category' }} selectLabel={{ value: '-1', label: 'Select One' }} /></div>
              <div className={style.bottomPad}><ComboBox label={'Type:'} onChange={setTypeId} values={[typeId]} valueList={{ list: props.types, key: 'id', value: 'name' }} selectLabel={{ value: '-1', label: 'Select One' }} /></div>
              {((props.subTypes.get('' + typeId)) !== undefined && (props.subTypes.get('' + typeId)) !== null) &&
                <div className={style.bottomPad}><ComboBox label={'SubType:'} onChange={setSubType} values={[subType]} valueList={{ list: props.subTypes.get('' + typeId), key: 'id', value: 'name' }} selectLabel={{ value: '-1', label: 'Select One' }} /></div>
              }
            </div>

            {/*
            <div className={style.twoColGrid2}>



              <div><button className={style.button} onClick={() => onSave()}>Save</button></div>
              {(props.index > -1) &&
                <div><button className={style.button} onClick={onDelete}>Delete</button></div>
              }

            </div>
            */}
            {error}
          </div>





        </div>
      );
    }
  } catch (error) {
    console.log('ERROR: ' + error.message);
    return '';
  }
}

export default GameEditor