/* eslint-disable */
import React, { useEffect, useState } from 'react';
import style from './style.module.scss'
import { useIntl } from 'react-intl'
import * as constants from 'newui/constants.js'
import * as backendService from 'newui/Services/Backend/backendService';
import { displayErrorNotification, displayInfoNotification } from 'newui/Utils/uiUtil';
import { setTheme } from 'newui/Utils/themeUtils';
import { displayPage } from 'newui/Utils/routerUtil';
import { useLocation, useNavigate } from 'react-router-dom';
import { getImageUrl } from 'newui/Utils/uiUtil';
import BlogElement from './BlogElement';
import Button from 'newui/Generic/Inputs/Button';
import DivClick from 'newui/Generic/Inputs/DivClick';
import ApiPersistence from 'newui/Utils/ApiPersistence';

function Blog(props) {

    const itemPerPage = 5;

    const [searchValue, setSearchValue] = useState('')
    const [categorySelected, setCategorySelected] = useState(-1)

    const navigate = useNavigate();
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState(1);
    const [nbrPage, setNbrPage] = useState(1);

    const [displayedList, setDisplayedList] = useState();
    const [blogList, setBlogList] = useState();
    const intl = useIntl()

    useEffect(() => {
        backendService.getBlogInformation(blogListSuccess, blogListFailure, navigate, location.pathname)
    }, []);



    function blogListSuccess(data) {
        if (data === null || data === undefined || data.length === 0) {
            displayPage(constants.ROUTE_HOME, navigate);
            displayInfoNotification(intl.formatMessage({ id: 'newcasino.info.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.no.blog', defaultMessage: 'Their is no article for the moment.' }));
        } else {
            setBlogList(data)
            setDisplayedList([...data])
            setCurrentPage(1);
            setNbrPage(((data.length % itemPerPage) === 0) ? (data.length / itemPerPage) : (Math.floor(data.length / itemPerPage)) + 1)

        }
    }

    function blogListFailure(data) {
        displayPage(constants.ROUTE_HOME, navigate);
        displayErrorNotification(intl.formatMessage({ id: 'newcasino.error.title', defaultMessage: 'Error' }), intl.formatMessage({ id: 'newcasino.error.fetching.blog', defaultMessage: 'Error trying to get blog information' }));
    }

    function getPreviousPage() {
        setCurrentPage(currentPage - 1)
    }

    function getNextPage() {
        setCurrentPage(currentPage + 1)
    }

    function userSearch(event) {
        setSearchValue(event.currentTarget.value);
        doFilter(false, -1, event.currentTarget.value)
    }

    function doFilter(changeCat, cat, searchText) {

        if (changeCat) {
            if (cat > -1) {
                setCategorySelected(cat);
            } else {
                setCategorySelected(-1);
            }
        } else {
            cat = categorySelected;
        }

        let newArray = [];

        for (let curs = 0; curs < blogList.length; curs++) {
            let current = blogList[curs];
            let search = searchText.trim();

            if (cat === -1 || current.category === cat) {
                if (searchText.length > 0) {
                    let lang = ApiPersistence.getInstance().getLanguageCode();
                    let title = (lang === 'pt' && current.titlePt.length > 0) ? current.titlePt : (lang === 'fr' && current.titleFr.length > 0) ? current.titleFr : current.titleEn;
                    let text = (lang === 'pt' && current.textPt.length > 0) ? current.textPt : (lang === 'fr' && current.textFr.length > 0) ? current.textFr : current.textEn;

                    if (title.toUpperCase().includes(searchText.toUpperCase()) || text.toUpperCase().includes(searchText.toUpperCase())) {
                        newArray.push(current);
                    }
                } else {
                    newArray.push(current);
                }
            }
        }

        setDisplayedList(newArray);
        setCurrentPage(1)
        setNbrPage(((newArray.length % itemPerPage) === 0) ? (newArray.length / itemPerPage) : (Math.floor(newArray.length / itemPerPage)) + 1)

    }

    return (
        <div id='contestWinnerPage' className={style.master_page_container}>
            <div className={style.topMainDiv}>
                <div>
                    <div className={style.case1grid}>
                        <div className={style.case1TopText2}>{intl.formatMessage({ id: 'newcasino.blog.title', defaultMessage: 'Blog' })}</div>
                        <div className={style.case1TopText3}>
                            <img src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                        </div>
                    </div>
                </div>
            </div>
            {blogList &&
                <div key={Math.random()} className={style.blogdetail_main}>
                    <div className={style.portraitSearch}>
                        <div>
                            <div className={`${style.blog_row}`}>
                                <div className={`${style.blog_searchBar} ${style.blog_search_grid}`}>
                                    <div className={`${style.blog_searchBar_input}`}>
                                        <input id='inputID' value={searchValue} autoFocus={true} maxLength={50} className={`${style.blog_searchBar_input_tag}`} placeholder={intl.formatMessage({ id: 'newcasino.blog.search', defaultMessage: 'Search' })} onChange={(e) => userSearch(e)} />
                                    </div>
                                    <div className={`${style.blog_searchBar_search}`}><i className="fa fa-search" aria-hidden="true"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.blogdetail_enveloppe}>
                        {(displayedList.length > 0) &&
                            <>
                                {
                                    displayedList.slice(((currentPage - 1) * itemPerPage), ((currentPage - 1) * itemPerPage) + itemPerPage).map((blog, index) => (
                                        <BlogElement key={'BLOGELEM_' + index} data={blog} />
                                    ))
                                }
                            </>
                        }
                        {(displayedList.length === 0) &&
                            <>{intl.formatMessage({ id: 'newcasino.blog.nothing', defaultMessage: 'No Article to display' })}</>
                        }
                    </div>
                    <div className={style.landscapeSearch}>
                        <div>
                            <div className={`${style.blog_row}`}>
                                <div className={`${style.blog_searchBar} ${style.blog_search_grid}`}>
                                    <div className={`${style.blog_searchBar_input}`}>
                                        <input id='inputID' value={searchValue} autoFocus={true} maxLength={50} className={`${style.blog_searchBar_input_tag}`} placeholder={intl.formatMessage({ id: 'newcasino.blog.search', defaultMessage: 'Search' })} onChange={(e) => userSearch(e)} />
                                    </div>
                                    <div className={`${style.blog_searchBar_search}`}><i className="fa fa-search" aria-hidden="true"></i></div>
                                </div>
                            </div>
                        </div>
                        <DivClick onClick={() => doFilter(true, -1, searchValue)} className={categorySelected === -1 ? style.catSelect : style.catNotSelect}>{categorySelected === -1 ? intl.formatMessage({ id: 'newcasino.blog.category.all', defaultMessage: 'All' }) : '[' + intl.formatMessage({ id: 'newcasino.blog.category.all', defaultMessage: 'All' }) + ']'}</DivClick>
                        <DivClick onClick={() => doFilter(true, 0, searchValue)} className={categorySelected === 0 ? style.catSelect : style.catNotSelect}>{categorySelected === 0 ? intl.formatMessage({ id: 'newcasino.blog.category.general', defaultMessage: 'General' }) : '[' + intl.formatMessage({ id: 'newcasino.blog.category.general', defaultMessage: 'General' }) + ']'}</DivClick>
                        <DivClick onClick={() => doFilter(true, 1, searchValue)} className={categorySelected === 1 ? style.catSelect : style.catNotSelect}>{categorySelected === 1 ? intl.formatMessage({ id: 'newcasino.blog.category.game', defaultMessage: 'Game Reviews' }) : '[' + intl.formatMessage({ id: 'newcasino.blog.category.game', defaultMessage: 'Game Reviews' }) + ']'}</DivClick>
                        <DivClick onClick={() => doFilter(true, 2, searchValue)} className={categorySelected === 2 ? style.catSelect : style.catNotSelect}>{categorySelected === 1 ? intl.formatMessage({ id: 'newcasino.blog.category.casino', defaultMessage: 'Casino' }) : '[' + intl.formatMessage({ id: 'newcasino.blog.category.casino', defaultMessage: 'Casino' }) + ']'}</DivClick>
                        <DivClick onClick={() => doFilter(true, 3, searchValue)} className={categorySelected === 3 ? style.catSelect : style.catNotSelect}>{categorySelected === 1 ? intl.formatMessage({ id: 'newcasino.blog.category.live', defaultMessage: 'Live Dealers' }) : '[' + intl.formatMessage({ id: 'newcasino.blog.category.live', defaultMessage: 'Live Dealers' }) + ']'}</DivClick>
                        <DivClick onClick={() => doFilter(true, 4, searchValue)} className={categorySelected === 4 ? style.catSelect : style.catNotSelect}>{categorySelected === 1 ? intl.formatMessage({ id: 'newcasino.blog.category.sport', defaultMessage: 'Sports Betting' }) : '[' + intl.formatMessage({ id: 'newcasino.blog.category.sport', defaultMessage: 'Sports Betting' }) + ']'}</DivClick>
                    </div>
                </div>
            }
            {(nbrPage > 1) &&
                <div className={style.pagination}>
                    <div>
                        <Button colorStyle='light' width='140px' active={currentPage > 1} value={intl.formatMessage({ id: 'newcasino.blog.admin.detail.button.previous', defaultMessage: 'Previous' })} marginTop='24px' onClick={getPreviousPage} />
                    </div>
                    <div>
                        <Button colorStyle='light' width='140px' active={currentPage < nbrPage} value={intl.formatMessage({ id: 'newcasino.blog.admin.detail.button.next', defaultMessage: 'Next' })} marginTop='24px' onClick={getNextPage} />
                    </div>
                </div>
            }
        </div>
    );
}
export default Blog;