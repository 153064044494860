//Generated by Java Application on Tue Feb 11 10:06:47 EST 2025

import localeAntd from 'antd/es/locale/en_US'

const messages = {
'casino.cashier.alert1.description': 'You need to complete your personal information to be able to deposit or withdraw. Click on this notice or visit the Edit Profile section.',
'casino.cashier.alert1.message': 'Important!',
'casino.cashier.alert2.description': "You need to verify your 'phone number' and 'add identity photo' to be able to withdraw. Click on this notice or visit the Edit Profile section.",
'casino.cashier.alert3.description': 'Your KYC documents has been rejected. Please upload new photos in your profile. Click on this notice or visit the "Edit Profile" section.',
'casino.cashier.currentBalance': 'Current Balance',
'casino.general.date.from': 'From',
'casino.general.date.to': 'to',
'casino.iframegame.title': 'CASINO',
'casino.modal.popup.close': 'Close',
'casino.rg.activitycheck': 'Activity check',
'casino.rg.activitycheckmsg': 'Option for setting a reminder for notifications outlining the time-spent playing.',
'casino.rg.depositlimit': 'Deposit Limit',
'casino.rg.depositlimitmsg': 'Set a limit to how much you can deposit in a given time period.',
'casino.rg.losslimit': 'Loss Limit',
'casino.rg.losslimitmsg': 'Set a limit on the maximum amount of money you can lose in a given time period.',
'casino.rg.selfexclusion': 'Self Exclusion',
'casino.rg.selfexclusionmsg': 'Take an indefinite amount of time off from us by requesting self-exclusion. (Minimum of 6 months)',
'casino.rg.setlimit': 'Set Limit',
'casino.rg.takebreak': 'Take a break',
'casino.rg.takebreakmsg': 'If you want to take a short break from gaming, you can do so by taking a break for a period of 1 Day, 7 days or 30 days.',
'casino.rg.title': 'Responsible Gambling',
'newCasino.admin.partnerDeal.title': "Partner's Deal",
'newCasino.admin.streamerDeal.amount': 'Amount',
'newCasino.admin.streamerDeal.cancel': 'Cancel',
'newCasino.admin.streamerDeal.giveaway': 'Giveaway amount',
'newCasino.admin.streamerDeal.perweek': 'Stream per week',
'newCasino.admin.streamerDeal.save': 'Save',
'newCasino.admin.streamerDeal.subtitle': "Adjust streamer's deal parameters",
'newCasino.admin.streamerDeal.title': "Streamer's Deal",
'newCasino.admin.streamerDeal.xtime': 'Wager X times',
'newcasino.admin.freespin.add.success': "{AMOUNT} freespin(s) have been added to the player's wallet.",
'newcasino.admin.freespin.remove.success': "{AMOUNT} freespin(s) have been removed from player's wallet.",
'newcasino.admin.kyc.approved': 'The KYC request has been approved',
'newcasino.admin.kyc.reject': 'The KYC request has been refused',
'newcasino.admin.money.add.success': "The amount of {AMOUNT} has been added to the player's account.",
'newcasino.admin.money.remove.success': "The amount of {AMOUNT} has been removed from the player's account.",
'newcasino.admin.pngpoints.add.success': '{AMOUNT} points have been added to the player.',
'newcasino.admin.pngpoints.remove.success': '{AMOUNT} points have been removed from player.',
'newcasino.admin.prom.depositbonus.tx.col1': 'Email',
'newcasino.admin.prom.depositbonus.tx.col2': 'Currency',
'newcasino.admin.prom.depositbonus.tx.col3': 'Prize',
'newcasino.admin.prom.depositbonus.winners': 'Winners',
'newcasino.admin.prom.wager.tx.col1': 'Email',
'newcasino.admin.prom.wager.tx.col2': 'Currency',
'newcasino.admin.prom.wager.tx.col3': 'Balance',
'newcasino.admin.prom.wager.tx.col4': 'Target',
'newcasino.admin.prom.wager.tx.col5': 'Prize',
'newcasino.admin.spinInfo.col1': 'Game Name',
'newcasino.admin.spinInfo.col2': 'Nbr Spin',
'newcasino.admin.user.address.address.error': 'Address is too short',
'newcasino.admin.user.address.address.hint': 'Type Address here',
'newcasino.admin.user.address.address.label': 'Address',
'newcasino.admin.user.address.city.error': 'City is too short',
'newcasino.admin.user.address.city.hint': 'Type City here',
'newcasino.admin.user.address.city.label': 'City',
'newcasino.admin.user.address.country.hint': 'Select country...',
'newcasino.admin.user.address.country.label': 'Country',
'newcasino.admin.user.address.currency.label': 'Currency',
'newcasino.admin.user.address.dob.label': 'Date Of Birth',
'newcasino.admin.user.address.email.label': 'Email',
'newcasino.admin.user.address.id.label': 'Id',
'newcasino.admin.user.address.phone.label': 'Phone Number',
'newcasino.admin.user.address.regdate.label': 'Registration Date',
'newcasino.admin.user.address.sponsorId.label': 'Sponsor Id',
'newcasino.admin.user.address.state.error': 'State is too short',
'newcasino.admin.user.address.state.hint': 'Type State here',
'newcasino.admin.user.address.state.label': 'State/Province',
'newcasino.admin.user.address.zip.error': 'Postal Code is too short',
'newcasino.admin.user.address.zip.hint': 'Postal Code',
'newcasino.admin.user.address.zip.label': 'Postal Code',
'newcasino.admin.user.button': 'Update',
'newcasino.admin.user.note.hint': "Add note to user's file",
'newcasino.admin.user.note.label': 'Note',
'newcasino.admin.user.pngpoint.hist.col1': 'Date',
'newcasino.admin.user.pngpoint.hist.col2': 'Type',
'newcasino.admin.user.pngpoint.hist.col3': 'Detail',
'newcasino.admin.user.pngpoint.hist.col4': 'Price',
'newcasino.admin.user.pngpoints.add.button': '+/- Points',
'newcasino.admin.user.pngpoints.available': 'Loyalty Points Available',
'newcasino.admin.user.pngpoints.daily.list.title': 'Daily Gift Details',
'newcasino.admin.user.pngpoints.level': 'Loyalty Level',
'newcasino.admin.user.pngpoints.purchase.list.title': 'Purchase Details',
'newcasino.admin.user.pngpoints.subtitle': 'Add/Remove Loyalty Points',
'newcasino.admin.user.pngpoints.title': 'Loyalty Points',
'newcasino.admin.user.pngpoints.used': 'Loyalty Points Used',
'newcasino.admin.user.popup.cash.amount.hint': 'Amount',
'newcasino.admin.user.popup.cash.amount.label': 'Amount',
'newcasino.admin.user.popup.cash.button.add': 'Add/Remove',
'newcasino.admin.user.popup.cash.button.cancel': 'Cancel',
'newcasino.admin.user.popup.cash.subtitle': "Add/Remove Real Money to/from player's wallet",
'newcasino.admin.user.popup.cash.title': 'Real Money',
'newcasino.admin.user.popup.freespin.button.add': 'Add/Remove',
'newcasino.admin.user.popup.freespin.button.cancel': 'Cancel',
'newcasino.admin.user.popup.freespin.info.subtitle': "Freespins Information from games's providers",
'newcasino.admin.user.popup.freespin.quantity.hint': 'Quantity',
'newcasino.admin.user.popup.freespin.quantity.label': 'Quantity',
'newcasino.admin.user.popup.freespin.subtitle': "Add/Remove Free spins to/from player's wallet",
'newcasino.admin.user.popup.freespin.title': 'Free Spins',
'newcasino.admin.user.popup.switchphone.button.add': 'Do it',
'newcasino.admin.user.popup.switchphone.button.cancel': 'Cancel',
'newcasino.admin.user.popup.switchphone.combo.label': 'Action',
'newcasino.admin.user.popup.switchphone.subtitle': 'Select the action to perform',
'newcasino.admin.user.popup.switchphone.success': 'The action has been completed',
'newcasino.admin.user.popup.switchphone.title': 'Change Phone Status',
'newcasino.admin.user.popup.switchstatus.button.add': 'Update',
'newcasino.admin.user.popup.switchstatus.button.cancel': 'Cancel',
'newcasino.admin.user.popup.switchstatus.combo.label': 'User Type',
'newcasino.admin.user.popup.switchstatus.subtitle': 'Select the new User Type',
'newcasino.admin.user.popup.switchstatus.success': 'The status has been modified',
'newcasino.admin.user.popup.switchstatus.title': 'Change User Status',
'newcasino.admin.user.popup.switchuser.button.add': 'Update',
'newcasino.admin.user.popup.switchuser.button.cancel': 'Cancel',
'newcasino.admin.user.popup.switchuser.combo.label': 'User Type',
'newcasino.admin.user.popup.switchuser.subtitle': 'Select the new user type',
'newcasino.admin.user.popup.switchuser.success': 'The User Type has been modified',
'newcasino.admin.user.popup.switchuser.title': 'Change User Type',
'newcasino.admin.user.popup.tx.cancel': 'Close',
'newcasino.admin.user.popup.tx.title': 'Transation Details',
'newcasino.admin.user.popup.wager.amount.hint': 'Amount',
'newcasino.admin.user.popup.wager.amount.label': 'Amount',
'newcasino.admin.user.popup.wager.button.add': 'Add',
'newcasino.admin.user.popup.wager.button.cancel': 'Cancel',
'newcasino.admin.user.popup.wager.expiration': 'Expiration Date',
'newcasino.admin.user.popup.wager.list.hint': 'One Email/UserId per line',
'newcasino.admin.user.popup.wager.list.label': 'Email/UserId List',
'newcasino.admin.user.popup.wager.max.hint': 'Maximum Win Amount',
'newcasino.admin.user.popup.wager.max.label': 'Maximum Win Amount',
'newcasino.admin.user.popup.wager.streamer': 'Is a streamer wager',
'newcasino.admin.user.popup.wager.subtitle': 'Provide Wager Information',
'newcasino.admin.user.popup.wager.title': 'Add Wager',
'newcasino.admin.user.popup.wager.x.hint': 'X Times',
'newcasino.admin.user.popup.wager.x.label': 'Money Turnover',
'newcasino.admin.user.update.success': "User's information has been modified.",
'newcasino.admin.user.wallet.add.freespin': '+/- FreeSpins',
'newcasino.admin.user.wallet.add.freespin.info': 'FreeSpins Info',
'newcasino.admin.user.wallet.add.money': '+/- Real Money',
'newcasino.admin.user.wallet.add.wager': 'Add Wager',
'newcasino.admin.user.wallet.col1': 'Type',
'newcasino.admin.user.wallet.col2': 'State',
'newcasino.admin.user.wallet.col3': 'Balance',
'newcasino.admin.user.wallet.col4': 'Wager Target',
'newcasino.admin.user.wallet.col5': 'Wager Win Bal.',
'newcasino.admin.user.wallet.col6': 'Free Spins',
'newcasino.admin.user.wallet.freespin.info.nodata': 'No freespins waiting at the provider',
'newcasino.admin.user.wallet.state.3xpending': 'Pending 3x Bonus',
'newcasino.admin.user.wallet.state.active': 'Active',
'newcasino.admin.user.wallet.state.inactive': 'Inactive',
'newcasino.admin.user.wallet.state.unknow': 'Unknow',
'newcasino.admin.user.wallet.state.unplayable': 'Not Playable',
'newcasino.admin.user.wallet.tx.col1': 'Date',
'newcasino.admin.user.wallet.tx.col2': 'Type',
'newcasino.admin.user.wallet.tx.col3': 'Amount',
'newcasino.admin.user.wallet.tx.col4': 'Balance',
'newcasino.admin.user.wallet.tx.title': "Wallet's transactions",
'newcasino.admin.user.wallet.type.realmoney': 'Real Money',
'newcasino.admin.user.wallet.type.streamer.wager': 'Streamer Wager',
'newcasino.admin.user.wallet.type.unknow': 'Unknown',
'newcasino.admin.user.wallet.type.wager': 'Wager',
'newcasino.admin.user.wallet.wallets.title': "User's Wallets",
'newcasino.admin.userlist.col1': 'Name',
'newcasino.admin.userlist.col2': 'Email',
'newcasino.admin.userlist.col3': 'Options',
'newcasino.admin.userlist.download': 'Download started',
'newcasino.admin.userlist.export': 'Export to CSV',
'newcasino.admin.userlist.list.filters': 'Filters',
'newcasino.admin.userlist.list.filters.banned': 'Banned',
'newcasino.admin.userlist.list.filters.kyc': 'KYC Pending',
'newcasino.admin.userlist.list.filters.streamers': 'Only Streamers',
'newcasino.admin.userlist.list.noresult': 'No Result',
'newcasino.admin.userlist.list.title': 'List of players',
'newcasino.admin.userlist.search': 'Search Users',
'newcasino.admin.userlist.title': 'User Management',
'newcasino.admin.wager.add.success': 'A Wager wallet has been created with an amount of {AMOUNT} - {X}x',
'newcasino.aff.affiliates.approve.fail': 'The attempts to approve the registration failed.',
'newcasino.aff.affiliates.approve.pass': 'User successfully approved',
'newcasino.aff.affiliates.get.list.fail': 'The attempts to retreive the affiliate list failed.',
'newcasino.aff.affiliates.pending.col.action': 'Action',
'newcasino.aff.affiliates.pending.col.approvalDate': 'Approval',
'newcasino.aff.affiliates.pending.col.avg': 'Average views',
'newcasino.aff.affiliates.pending.col.email': 'Email address',
'newcasino.aff.affiliates.pending.col.name': 'Name',
'newcasino.aff.affiliates.pending.col.twitch': 'Twitch',
'newcasino.aff.affiliates.reject.fail': 'The attempts to reject the registration failed.',
'newcasino.aff.affiliates.reject.pass': 'User successfully rejected',
'newcasino.aff.auth.footer.contact': 'Contact us',
'newcasino.aff.auth.footer.copy': '© Copyright 2024 Numbers Game. All rights reserved.',
'newcasino.aff.auth.footer.policy': 'Privacy policy',
'newcasino.aff.auth.footer.terms': 'Terms & conditions',
'newcasino.aff.auth.forget.button.back': 'Back',
'newcasino.aff.auth.forget.button.reset': 'Reset Password',
'newcasino.aff.auth.forget.fail': 'Impossible to send the email, please contact us.',
'newcasino.aff.auth.forget.sub.title': 'No problem, type your email and you will receive an email to reset it.',
'newcasino.aff.auth.forget.success': 'Check your email!',
'newcasino.aff.auth.forget.title': 'You forget your password?',
'newcasino.aff.auth.login.button.login': 'Login',
'newcasino.aff.auth.login.button.register': 'Register',
'newcasino.aff.auth.login.email': 'Email address',
'newcasino.aff.auth.login.email.err': 'Invalid Email',
'newcasino.aff.auth.login.email.hint': 'Type your email here',
'newcasino.aff.auth.login.err.fail': 'Invalid username or password.',
'newcasino.aff.auth.login.forget': 'Forget your password?',
'newcasino.aff.auth.login.password': 'Password',
'newcasino.aff.auth.login.stay': 'Stay connected',
'newcasino.aff.auth.login.sub.title': 'Enter the required information to login or register to open a new account.',
'newcasino.aff.auth.login.title': 'Welcome to the Numbers Game back-office',
'newcasino.aff.auth.reg.cie': 'Company name (optional)',
'newcasino.aff.auth.reg.cie.err': 'Invalid',
'newcasino.aff.auth.reg.country': 'Country',
'newcasino.aff.auth.reg.country.hint': 'Select your country...',
'newcasino.aff.auth.reg.currency': 'Currency',
'newcasino.aff.auth.reg.currency.hint': 'Select your currency...',
'newcasino.aff.auth.reg.dob': 'Date of birth',
'newcasino.aff.auth.reg.dob.err': 'Invalid Birthday - 18+',
'newcasino.aff.auth.reg.dob.hint': 'Ex.: dd/mm/yyyy',
'newcasino.aff.auth.reg.email': 'Email address',
'newcasino.aff.auth.reg.email.err': 'Invalid Email',
'newcasino.aff.auth.reg.err.fail': 'Impossible to register, please contact us.',
'newcasino.aff.auth.reg.fullname': 'Full name',
'newcasino.aff.auth.reg.fullname.err': 'Invalid',
'newcasino.aff.auth.reg.password': 'Password',
'newcasino.aff.auth.reg.password.err': 'You need at less 8 characters with a mix of uppercase and lowercase',
'newcasino.aff.auth.reg.password2': 'Confirm password',
'newcasino.aff.auth.reg.password2.err': 'Does Not match',
'newcasino.aff.auth.reg.streaming': 'Streaming Page (optional)',
'newcasino.aff.auth.reg.streaming.err': 'Invalid',
'newcasino.aff.auth.reg.sub.channel': 'Your channels',
'newcasino.aff.auth.reg.sub.connection': 'Connection information',
'newcasino.aff.auth.reg.sub.personnal': 'Personnal information',
'newcasino.aff.auth.reg.sub.title': 'Enter the required information to register.',
'newcasino.aff.auth.reg.title': 'Sign up',
'newcasino.aff.auth.reg.views': 'Average views (optional)',
'newcasino.aff.auth.reg.views.err': 'Invalid',
'newcasino.aff.auth.reg.website': 'Website (optional)',
'newcasino.aff.auth.reg.website.err': 'Invalid',
'newcasino.aff.commissions.calendar.1': 'January',
'newcasino.aff.commissions.calendar.10': 'October',
'newcasino.aff.commissions.calendar.11': 'November',
'newcasino.aff.commissions.calendar.12': 'December',
'newcasino.aff.commissions.calendar.2': 'February',
'newcasino.aff.commissions.calendar.3': 'March',
'newcasino.aff.commissions.calendar.4': 'April',
'newcasino.aff.commissions.calendar.5': 'May',
'newcasino.aff.commissions.calendar.6': 'June',
'newcasino.aff.commissions.calendar.7': 'July',
'newcasino.aff.commissions.calendar.8': 'August',
'newcasino.aff.commissions.calendar.9': 'September',
'newcasino.aff.commissions.detail.amount': 'Amount',
'newcasino.aff.commissions.detail.comm': 'Commission',
'newcasino.aff.commissions.detail.fixed': 'Fixed Amount',
'newcasino.aff.commissions.detail.ftd': 'FTD',
'newcasino.aff.commissions.detail.ftd.no': 'NO',
'newcasino.aff.commissions.detail.ftd.yes': 'YES',
'newcasino.aff.commissions.detail.no': 'No Commission rule set for this user',
'newcasino.aff.commissions.detail.perc': 'Percentage',
'newcasino.aff.commissions.detail.percdeposit': '% Deposit',
'newcasino.aff.commissions.detail.percvault': '% Vault',
'newcasino.aff.commissions.detail.subtotal': 'Sub Total',
'newcasino.aff.commissions.detail.total': 'Total',
'newcasino.aff.commissions.detail.type': 'Commission Type',
'newcasino.aff.commissions.grid.currency': 'Grid Currency:',
'newcasino.aff.commissions.grid.maxWin': 'Max Win Total:',
'newcasino.aff.commissions.grid.title': 'Vault Payment Percentage based on deposit',
'newcasino.aff.commissions.grid.unlimited': 'Unlimited',
'newcasino.aff.commissions.load.fail': 'Impossible to load commissions, contact support!',
'newcasino.aff.commissions.nopay.button': 'Trash',
'newcasino.aff.commissions.notpaid.button': 'Not Paid yet',
'newcasino.aff.commissions.paid.button': 'Paid',
'newcasino.aff.commissions.paid.total': 'Paid Commission:',
'newcasino.aff.commissions.pay.button': 'Pay',
'newcasino.aff.commissions.pay.convRate': 'Conv. Rate',
'newcasino.aff.commissions.pay.fixed': 'Monthly Fixed Amount',
'newcasino.aff.commissions.pay.minftd': 'Min. FTD',
'newcasino.aff.commissions.pay.paymCurrency': 'Calculation made in currency:',
'newcasino.aff.commissions.pay.paymDate': 'Payment date:',
'newcasino.aff.commissions.pay.percDeposit': '% On Deposit',
'newcasino.aff.commissions.pay.percVault': '% On Vault',
'newcasino.aff.commissions.pay.success': 'The streamer has been paid.',
'newcasino.aff.commissions.pay.total': 'Total Commission:',
'newcasino.aff.commissions.pay.usegrid': 'Use Grid',
'newcasino.aff.commissions.refused.button': 'Refused',
'newcasino.aff.commissions.title': 'Commissions',
'newcasino.aff.commissions.trash.success': 'The streamer has not been paid.',
'newcasino.aff.contactus.add1': 'First Floor, Millennium House,',
'newcasino.aff.contactus.add2': 'Victoria Road, Douglas, IM24RW,',
'newcasino.aff.contactus.add3': 'Isle of Man',
'newcasino.aff.contactus.button.send': 'Send your message',
'newcasino.aff.contactus.email': 'What is your email?',
'newcasino.aff.contactus.msg': 'Type your message here',
'newcasino.aff.contactus.name': 'What is your name?',
'newcasino.aff.contactus.sub1': 'Have a question? Contact us',
'newcasino.aff.contactus.sub2': 'Where to find us?',
'newcasino.aff.contactus.subject': 'What is the subject?',
'newcasino.aff.contactus.success': 'Thanks, we received your message',
'newcasino.aff.contactus.title': 'Contact Us',
'newcasino.aff.dashboard.active.player': 'Active Player',
'newcasino.aff.dashboard.commission.link': 'See all commissons',
'newcasino.aff.dashboard.dailygift.open': 'Total number of daily gifts opened',
'newcasino.aff.dashboard.first.time.deposit.player': 'First Time Deposit',
'newcasino.aff.dashboard.load.fail': 'Impossible to load dashboard, contact support!',
'newcasino.aff.dashboard.period.lm': 'Last Month',
'newcasino.aff.dashboard.period.lw': 'Last week',
'newcasino.aff.dashboard.period.ly': 'Last year',
'newcasino.aff.dashboard.period.tm': 'This Month',
'newcasino.aff.dashboard.period.today': 'Today',
'newcasino.aff.dashboard.period.tw': 'This week',
'newcasino.aff.dashboard.period.ty': 'This year',
'newcasino.aff.dashboard.period.yest': 'Yesterday',
'newcasino.aff.dashboard.refId': 'Referal Link',
'newcasino.aff.dashboard.refId.copied': 'Copied to clipboard',
'newcasino.aff.dashboard.registration.total.email.verified': 'Email Verified',
'newcasino.aff.dashboard.registration.total.player': 'New Players',
'newcasino.aff.dashboard.registration.total.started': 'Registration - Started/Completed',
'newcasino.aff.dashboard.sponsered.players': 'Total number of sponsered players',
'newcasino.aff.dashboard.title': 'Dashboard',
'newcasino.aff.dashboard.total.10.commission': 'Paid Commissions',
'newcasino.aff.dashboard.total.deposit': 'Total Deposit',
'newcasino.aff.dashboard.total.deposit.graph.last12Months': 'Last Months Deposits',
'newcasino.aff.dashboard.total.deposit.graph.last7Days': 'Last Days Deposits',
'newcasino.aff.dashboard.total.deposit.graph.last7SameDay': 'Last {DAY}',
'newcasino.aff.dashboard.total.deposit.graph.last7Weeks': 'Last Weeks Deposits',
'newcasino.aff.menuleft.affiliates': 'Affiliates',
'newcasino.aff.menuleft.commissions': 'Commissions',
'newcasino.aff.menuleft.copyright1': '© Copyright 2024 Numbers Game',
'newcasino.aff.menuleft.copyright2': 'All rights reserved.',
'newcasino.aff.menuleft.dashboard': 'Dashboard',
'newcasino.aff.policy.button': 'Close',
'newcasino.aff.policy.title': 'Privacy policy',
'newcasino.aff.regsuccess.button': 'Continue',
'newcasino.aff.regsuccess.msg': 'Thank you for registering with us! One of your affiliate managers will get in touch with you within 48 hours.',
'newcasino.aff.regsuccess.title': 'Welcome!',
'newcasino.aff.terms.button': 'Close',
'newcasino.aff.terms.title': 'Terms & conditions',
'newcasino.aff.userlist.approve': 'Approve',
'newcasino.aff.userlist.approved': 'Members',
'newcasino.aff.userlist.emtpy': 'No User to show',
'newcasino.aff.userlist.pending': 'Pending Approval',
'newcasino.aff.userlist.reject': 'Reject',
'newcasino.aff.userlist.sub.title': 'Affiliates list',
'newcasino.aff.userlist.title': 'Affiliates',
'newcasino.auth.log.error.badcredential': 'Incorrect email or password',
'newcasino.auth.log.error.break': 'You took a break from the game! You can login again on ',
'newcasino.auth.log.error.emailnotverified': 'Your email is not verified. Please go to your mailbox and click on the verification link. If you want to received another verification email, click this message.',
'newcasino.auth.log.error.unknown': 'Oups... Something went wrong, please contact support',
'newcasino.auth.log.msg.passreset.msg': 'We just sent you an email containing a link to reset your password.',
'newcasino.auth.log.msg.passreset.title': 'Check your inbox!',
'newcasino.auth.log.msg.verifemail.msg': 'We just sent you an email containing a link to verify your email address.',
'newcasino.auth.log.msg.verifemail.title': 'Check your inbox!',
'newcasino.auth.login.avail.freespin': 'You have freespins available, you can use them on one of the games under the "Freespins" Tab just below.',
'newcasino.auth.login.button.cancel': 'Cancel',
'newcasino.auth.login.button.login': 'Login',
'newcasino.auth.login.button.noaccount': "Don't have an account ?",
'newcasino.auth.login.button.signup': 'Sign up now!',
'newcasino.auth.login.email': 'Email',
'newcasino.auth.login.email.err': 'Invalid Email',
'newcasino.auth.login.forgot_password': 'Forgot Password?',
'newcasino.auth.login.password': 'Password',
'newcasino.auth.login.reset.button.send': 'Send Email',
'newcasino.auth.login.reset.subTitle': 'You forgot your password? No problem, we will send you a email to reset it.',
'newcasino.auth.login.reset.title': 'Reset Password',
'newcasino.auth.login.stayconnected': 'Stay Connected',
'newcasino.auth.login.subTitle': 'Welcome to Numbers Game! Please provide your login information',
'newcasino.auth.login.title': 'Login',
'newcasino.auth.login.type.email': 'Type your email here',
'newcasino.auth.login.type.password': 'Type your new password here',
'newcasino.auth.registration.address.address.error': 'Your address is too short',
'newcasino.auth.registration.address.address.hint': 'Type your address here',
'newcasino.auth.registration.address.address.label': 'Address',
'newcasino.auth.registration.address.already': 'Already have an account?',
'newcasino.auth.registration.address.back': 'Back',
'newcasino.auth.registration.address.city.error': 'Your city is too short',
'newcasino.auth.registration.address.city.hint': 'Type your city here',
'newcasino.auth.registration.address.city.label': 'City',
'newcasino.auth.registration.address.country.hint': 'Select your country...',
'newcasino.auth.registration.address.country.label': 'Country',
'newcasino.auth.registration.address.currency.hint': 'Select your currency...',
'newcasino.auth.registration.address.currency.label': 'Currency',
'newcasino.auth.registration.address.next': 'Next Step 4/5',
'newcasino.auth.registration.address.phone.error': 'Too short',
'newcasino.auth.registration.address.phone.hint': 'Phone Number',
'newcasino.auth.registration.address.phone.label': 'Phone Number',
'newcasino.auth.registration.address.phonecode.hint': 'Select code...',
'newcasino.auth.registration.address.phonecode.label': 'Phone - Country code',
'newcasino.auth.registration.address.state.error': 'Your state is too short',
'newcasino.auth.registration.address.state.hint': 'State',
'newcasino.auth.registration.address.state.label': 'State/Province',
'newcasino.auth.registration.address.subTitle': 'Enter the required information to register.',
'newcasino.auth.registration.address.title': 'Registration',
'newcasino.auth.registration.address.zip.error': 'Your Postal Code is too short',
'newcasino.auth.registration.address.zip.hint': 'Postal Code',
'newcasino.auth.registration.address.zip.label': 'Postal Code',
'newcasino.auth.registration.bonus.already': 'Already have an account?',
'newcasino.auth.registration.bonus.already.link': 'Login',
'newcasino.auth.registration.bonus.cancel': 'Cancel',
'newcasino.auth.registration.bonus.cashback': 'Get 3X your first deposit up to 1500$ in Bonus',
'newcasino.auth.registration.bonus.cashback.subtitle': '*Wagering requirement of 35x',
'newcasino.auth.registration.bonus.next': 'Next Step 1/5',
'newcasino.auth.registration.bonus.none': 'No Bonuses',
'newcasino.auth.registration.bonus.promocode': 'I have promo code!',
'newcasino.auth.registration.bonus.refer': 'Refer-a-friend',
'newcasino.auth.registration.bonus.refer.desc': 'Deposit and play a minimum of 30$ and both you and your friend will be awarded 15 free spins!',
'newcasino.auth.registration.bonus.refer2': 'Proceed to the next step or choose a promotion.',
'newcasino.auth.registration.bonus.refered': 'You friend refered you! ',
'newcasino.auth.registration.bonus.subTitle': 'Welcome to Numbers Game! Choose a welcome bonus to proceed.',
'newcasino.auth.registration.bonus.title': 'Registration',
'newcasino.auth.registration.completed.next': 'Go to login page',
'newcasino.auth.registration.completed.text1': 'You have successfully',
'newcasino.auth.registration.completed.text2': 'completed your registration',
'newcasino.auth.registration.completed.text3': '! You can now access your account and deposit.',
'newcasino.auth.registration.completed.text4': 'Please note!',
'newcasino.auth.registration.completed.text5': 'You must complete your KYC form in order to withdraw. You can do this in your profile.',
'newcasino.auth.registration.completed.title': 'Registration completed',
'newcasino.auth.registration.cred.already': 'Already have an account?',
'newcasino.auth.registration.cred.back': 'Back',
'newcasino.auth.registration.cred.email.error': 'Invalid Email',
'newcasino.auth.registration.cred.email.hint': 'Type your email here',
'newcasino.auth.registration.cred.email.label': 'Email',
'newcasino.auth.registration.cred.license1': 'I agree to the',
'newcasino.auth.registration.cred.license2': 'terms and conditions',
'newcasino.auth.registration.cred.license3': 'and',
'newcasino.auth.registration.cred.license4': 'privacy policy *',
'newcasino.auth.registration.cred.next': 'Next Step 2/5',
'newcasino.auth.registration.cred.password.button.submit': 'Submit',
'newcasino.auth.registration.cred.password.error': 'You need at less 8 characters with a mix of uppercase and lowercase',
'newcasino.auth.registration.cred.password.hint': 'Type your new password here',
'newcasino.auth.registration.cred.password.label': 'Password',
'newcasino.auth.registration.cred.promotion.error': 'Invalid Promotion Code',
'newcasino.auth.registration.cred.promotion.hint': 'Type your promotion code here',
'newcasino.auth.registration.cred.promotion.label': 'Promotion Code',
'newcasino.auth.registration.cred.subTitle': 'Enter the required information to register.',
'newcasino.auth.registration.cred.title': 'Registration',
'newcasino.auth.registration.currency.australia': 'Australian Dollar',
'newcasino.auth.registration.currency.brazil': 'Brazilian Real',
'newcasino.auth.registration.currency.canada': 'Canadian Dollar',
'newcasino.auth.registration.currency.europe': 'European Euro',
'newcasino.auth.registration.currency.usa': 'American Dollar',
'newcasino.auth.registration.emailcode.already': 'Already have an account?',
'newcasino.auth.registration.emailcode.back': 'Back',
'newcasino.auth.registration.emailcode.hint': 'Type your code here',
'newcasino.auth.registration.emailcode.label': 'Code',
'newcasino.auth.registration.emailcode.next': 'Complete',
'newcasino.auth.registration.emailcode.resend': 'Did not received the email',
'newcasino.auth.registration.emailcode.resend.link': 'Click here to send it again',
'newcasino.auth.registration.emailcode.subTitle': 'We have sent a confirmation code to your email address. Enter the code to continue your registration.',
'newcasino.auth.registration.emailcode.title': 'Registration',
'newcasino.auth.registration.mobilecode.already': 'Already have an account?',
'newcasino.auth.registration.mobilecode.back': 'Back',
'newcasino.auth.registration.mobilecode.hint': 'Type your code here',
'newcasino.auth.registration.mobilecode.label': 'Code',
'newcasino.auth.registration.mobilecode.next': 'Next Step 5/5',
'newcasino.auth.registration.mobilecode.resend': 'Did not received the sms?',
'newcasino.auth.registration.mobilecode.resend.link': 'Click here to send it again',
'newcasino.auth.registration.mobilecode.subTitle': 'We have sent a confirmation code to your phone number. Enter the code to complete your registration.',
'newcasino.auth.registration.mobilecode.title': 'Registration',
'newcasino.auth.registration.userinfo.already': 'Already have an account?',
'newcasino.auth.registration.userinfo.back': 'Back',
'newcasino.auth.registration.userinfo.birthday.error': 'Invalid Birthday - 18+',
'newcasino.auth.registration.userinfo.birthday.hint': 'Ex.: dd/mm/yyyy',
'newcasino.auth.registration.userinfo.birthday.label': 'Birthday',
'newcasino.auth.registration.userinfo.firstname.error': 'Your first name is too short',
'newcasino.auth.registration.userinfo.firstname.hint': 'Type your first name here',
'newcasino.auth.registration.userinfo.firstname.label': 'First Name',
'newcasino.auth.registration.userinfo.lastname.error': 'Your last name is too short',
'newcasino.auth.registration.userinfo.lastname.hint': 'Type your last name here',
'newcasino.auth.registration.userinfo.lastname.label': 'Last Name',
'newcasino.auth.registration.userinfo.next': 'Next Step 3/5',
'newcasino.auth.registration.userinfo.subTitle': 'Enter the required information to register.',
'newcasino.auth.registration.userinfo.title': 'Registration',
'newcasino.batch.admin.button.wager': 'Add Wager',
'newcasino.batch.admin.title': 'Batch Jobs',
'newcasino.birthday.gift': 'HAPPY BIRTHDAY!!! For your birthday, we gave you {QTE} freespins.',
'newcasino.blog.admin.detail.active': 'Active',
'newcasino.blog.admin.detail.body.en': 'Content',
'newcasino.blog.admin.detail.body.en.hint': 'Type the content in English',
'newcasino.blog.admin.detail.body.fr': 'Content',
'newcasino.blog.admin.detail.body.fr.hint': 'Type the content in French',
'newcasino.blog.admin.detail.body.pt': 'Content',
'newcasino.blog.admin.detail.body.pt.hint': 'Type the content in Portuguese',
'newcasino.blog.admin.detail.button.delete': 'Delete',
'newcasino.blog.admin.detail.button.next': 'Next Page',
'newcasino.blog.admin.detail.button.previous': 'Previous Page',
'newcasino.blog.admin.detail.button.save': 'Save',
'newcasino.blog.admin.detail.button.upload': 'Upload',
'newcasino.blog.admin.detail.header.title.blog': 'Blog Editor',
'newcasino.blog.admin.detail.header.title.missing': '???',
'newcasino.blog.admin.detail.image.url': 'Image',
'newcasino.blog.admin.detail.internal.name': 'Internal Name',
'newcasino.blog.admin.detail.internal.name.hint': 'Type the Internal Name',
'newcasino.blog.admin.detail.tab.english': 'English',
'newcasino.blog.admin.detail.tab.french': 'French',
'newcasino.blog.admin.detail.tab.portuguese': 'Portuguese',
'newcasino.blog.admin.detail.title.en': 'Title English',
'newcasino.blog.admin.detail.title.en.hint': 'Type the title in English',
'newcasino.blog.admin.detail.title.fr': 'Title French',
'newcasino.blog.admin.detail.title.fr.hint': 'Type the title in French',
'newcasino.blog.admin.detail.title.pt': 'Title Portuguese',
'newcasino.blog.admin.detail.title.pt.hint': 'Type the title in Portuguese',
'newcasino.blog.admin.list.add.button': 'New Article',
'newcasino.blog.admin.list.col1': 'Creation Date',
'newcasino.blog.admin.list.col2': 'Name',
'newcasino.blog.admin.list.col3': 'Active',
'newcasino.blog.admin.list.no': 'No',
'newcasino.blog.admin.list.title': 'Blog management',
'newcasino.blog.admin.list.yes': 'Yes',
'newcasino.blog.category': 'Category',
'newcasino.blog.category.all': 'All',
'newcasino.blog.category.casino': 'Casino',
'newcasino.blog.category.game': 'Game Reviews',
'newcasino.blog.category.general': 'General',
'newcasino.blog.category.live': 'Live Dealers',
'newcasino.blog.category.sport': 'Sports Betting',
'newcasino.blog.nothing': 'No Article to display',
'newcasino.blog.search': 'Search Blog',
'newcasino.blog.title': 'Blog',
'newcasino.cashier.history.col1': 'Date',
'newcasino.cashier.history.col2': 'Transaction Type',
'newcasino.cashier.history.col3': 'Method',
'newcasino.cashier.history.col4': 'Amount',
'newcasino.cashier.history.col5': 'Status',
'newcasino.cashier.history.subTitle': 'These are all your transactions in the last 30 days',
'newcasino.cashier.history.title': 'Transaction History',
'newcasino.cashier.history.tx.cancelled': 'Cancelled',
'newcasino.cashier.history.tx.completed': 'Completed',
'newcasino.cashier.history.tx.deposit': 'Deposit',
'newcasino.cashier.history.tx.pending': 'Pending',
'newcasino.cashier.history.tx.withdrawal': 'Withdrawal',
'newcasino.cashier.subtitle': 'From here, you can make deposit and withdrawal of money',
'newcasino.cashier.tab.back': 'Back',
'newcasino.cashier.tab.history': 'Transaction History',
'newcasino.cashier.title': 'CASHIER',
'newcasino.cashier.vault': 'Vault',
'newcasino.contactus.button.send': 'Send Message',
'newcasino.contactus.captcha': 'You must check to Captcha checkbox',
'newcasino.contactus.email.error': 'Invalid Email address',
'newcasino.contactus.failure': 'The message could not be sent',
'newcasino.contactus.message': 'Message',
'newcasino.contactus.message.error': 'Message is too short',
'newcasino.contactus.message.hint': 'Message',
'newcasino.contactus.name': 'Full Name',
'newcasino.contactus.name.email': 'Email',
'newcasino.contactus.name.email.hint': 'Type your email here',
'newcasino.contactus.name.error': 'Your name is too short',
'newcasino.contactus.name.hint': 'Type your name here',
'newcasino.contactus.question': 'HAVE A QUESTION? CONTACT US!',
'newcasino.contactus.subject': 'Subject',
'newcasino.contactus.subject.error': 'Subject is too short',
'newcasino.contactus.subject.hint': 'Subject',
'newcasino.contactus.success': 'Thank you, we have received your message',
'newcasino.contactus.title': 'Contact Us',
'newcasino.contactus.where': 'WHERE TO FIND US?',
'newcasino.contests.admin.detail.active': 'Active',
'newcasino.contests.admin.detail.bullet.en': 'Bullet English',
'newcasino.contests.admin.detail.bullet.en.hint': 'English',
'newcasino.contests.admin.detail.bullet.fr': 'Bullet French',
'newcasino.contests.admin.detail.bullet.fr.hint': 'French',
'newcasino.contests.admin.detail.bullet.pt': 'Bullet Portuguese',
'newcasino.contests.admin.detail.bullet.pt.hint': 'Portuguese',
'newcasino.contests.admin.detail.button.add': '+',
'newcasino.contests.admin.detail.button.delete': 'Delete',
'newcasino.contests.admin.detail.button.save': 'Save',
'newcasino.contests.admin.detail.date.end': 'End Date',
'newcasino.contests.admin.detail.date.end.hint': 'Ex: dd/mm/yyyy',
'newcasino.contests.admin.detail.date.start': 'Start Date',
'newcasino.contests.admin.detail.date.start.hint': 'Ex: dd/mm/yyyy',
'newcasino.contests.admin.detail.desc.en': 'Description English',
'newcasino.contests.admin.detail.desc.en.hint': 'Type the description in English',
'newcasino.contests.admin.detail.desc.fr': 'Description French',
'newcasino.contests.admin.detail.desc.fr.hint': 'Type the description in French',
'newcasino.contests.admin.detail.desc.pt': 'Description Portuguese',
'newcasino.contests.admin.detail.desc.pt.hint': 'Type the description in Portuguese',
'newcasino.contests.admin.detail.header.title.missing': '???',
'newcasino.contests.admin.detail.header.winners': 'The Winners',
'newcasino.contests.admin.detail.title.en': 'Title English',
'newcasino.contests.admin.detail.title.en.hint': 'Type the title in English',
'newcasino.contests.admin.detail.title.fr': 'Title French',
'newcasino.contests.admin.detail.title.fr.hint': 'Type the title in French',
'newcasino.contests.admin.detail.title.pt': 'Title Portuguese',
'newcasino.contests.admin.detail.title.pt.hint': 'Type the title in Portuguese',
'newcasino.contests.admin.detail.win.button.add': '+',
'newcasino.contests.admin.detail.win.date': 'Date',
'newcasino.contests.admin.detail.win.date.hint': 'Ex: dd/mm/yyyy',
'newcasino.contests.admin.detail.win.name': "Winner's name",
'newcasino.contests.admin.detail.win.name.hint': "Type Winner's Name",
'newcasino.contests.admin.detail.win.prize.en': 'Prize English',
'newcasino.contests.admin.detail.win.prize.en.hint': 'English',
'newcasino.contests.admin.detail.win.prize.fr': 'Prize French',
'newcasino.contests.admin.detail.win.prize.fr.hint': 'French',
'newcasino.contests.admin.detail.win.prize.pt': 'Prize Portuguese',
'newcasino.contests.admin.detail.win.prize.pt.hint': 'Portuguese',
'newcasino.contests.admin.list.add.button': 'Add New Contest',
'newcasino.contests.admin.list.col1': 'Contest Date',
'newcasino.contests.admin.list.col2': 'Contest Name',
'newcasino.contests.admin.list.col3': 'Display to users',
'newcasino.contests.admin.list.no': 'No',
'newcasino.contests.admin.list.title': 'Contest Management',
'newcasino.contests.admin.list.yes': 'Yes',
'newcasino.contests.other': 'Other contest(s)',
'newcasino.contests.winner.no': 'Contest winner no.',
'newcasino.contests.winner.none': 'No winner yet!',
'newcasino.contests.winner.none2': 'Future Winner(s) will appear right here',
'newcasino.contests.winner.of': 'Winner of',
'newcasino.days.1': 'Sundays',
'newcasino.days.2': 'Mondays',
'newcasino.days.3': 'Tuesdays',
'newcasino.days.4': 'Wednesdays',
'newcasino.days.5': 'Thursdays',
'newcasino.days.6': 'Fridays',
'newcasino.days.7': 'Saturdays',
'newcasino.error.404': "The requested page can't be found",
'newcasino.error.500': 'Server error, please try again in a few moments',
'newcasino.error.contact.support': 'An unexpected error occurs. Please contact support.',
'newcasino.error.delete.blog': 'Operation Failed',
'newcasino.error.delete.contest': 'Error trying to delete contest',
'newcasino.error.delete.popup': 'Operation Failed',
'newcasino.error.email.send.failed': 'We were not able to send a verification code to your email address, Please contact support.',
'newcasino.error.email.send.success': "The email has been send again. Please check your spam if you don't find it.",
'newcasino.error.email.validation.failed': 'This code is invalid, please try again',
'newcasino.error.fetching.blog': 'Error trying to get blog information',
'newcasino.error.fetching.contest': 'Error trying to get contests information',
'newcasino.error.fetching.popup': 'Error trying to get popup information',
'newcasino.error.game.not.available': 'Game not available',
'newcasino.error.game.not.available.msg': 'This game cannot be access at this time',
'newcasino.error.need.account': 'Account Needed',
'newcasino.error.need.account.to.play': 'You need to login or register to access this game',
'newcasino.error.no.blog': 'Their is no article for the moment.',
'newcasino.error.no.contest': 'Their is no contest at the moment.',
'newcasino.error.save.contest': 'Error trying to save the contest',
'newcasino.error.save.popup': 'Operation Failed',
'newcasino.error.sms.send.failed': 'We were not able to send a verification code to your phone number, Please contact support.',
'newcasino.error.sms.send.success': 'The sms has been resend to your phone',
'newcasino.error.sms.validation.failed': 'This code is invalid, please try again',
'newcasino.error.title': 'Error',
'newcasino.error.user.creation.failed': "This email is already in used. If it's yours, you can simply reset the password.",
'newcasino.faq.a1': "Click on the login tab from the profile button and then on 'Forgot Password?'. From there follow the steps and you will have retrieve your password.",
'newcasino.faq.a2': 'You can change your password at any time. Simply login to your account, go to Edit Profile page and click Change password. You will need to enter your old password along with the new one you have selected.',
'newcasino.faq.a3': 'All major credit cards, prepaid cards and cryptocurrencies.',
'newcasino.faq.a4': "Some financial institutions don't accept transactions coming from 'E-Gaming' websites; therefore you should try a different card or method of payment. Most credit cards with cash reward programs will likely not work on the site.",
'newcasino.faq.a5': "When you access Numbers Game, a notification pop up will appear and ask you if you want to add Numbers Game to your home screen. Simply click on that link and the Numbers Game icon will appear on your phone's home page.",
'newcasino.faq.contact': 'Contact Us',
'newcasino.faq.hesitate': 'Do not hesitate to contact us for further answers.',
'newcasino.faq.more': 'MORE QUESTIONS?',
'newcasino.faq.q1': 'What do I do if I forgot my account password?',
'newcasino.faq.q2': 'Can I change my password?',
'newcasino.faq.q3': 'Which methods of payments can I use to make a deposit?',
'newcasino.faq.q4': 'My credit card was declined for an unknown reason, what should I do?',
'newcasino.faq.q5': 'I want to access Numbers Game more quickly. What can I do?',
'newcasino.faq.title': 'FAQ',
'newcasino.footer.col1.address': 'First Floor, Millennium House Victoria Road, Douglas, IM24RW Isle of Man',
'newcasino.footer.col1.contactus': 'Contact us',
'newcasino.footer.col2.learnmore': 'Learn more',
'newcasino.footer.col2.text': 'Numbers Games is owned by GAC Group Limited and operated under the Kahnawake Gaming License of GAC Group Limited, a company incorporated in the Isle of Man.',
'newcasino.footer.col2.title': 'About us',
'newcasino.footer.col3.link.aboutus': 'About us',
'newcasino.footer.col3.link.casino': 'Casino',
'newcasino.footer.col3.link.contact': 'Contact',
'newcasino.footer.col3.link.home': 'Home',
'newcasino.footer.col3.link.livecasino': 'Live Casino',
'newcasino.footer.col3.link.login': 'Login',
'newcasino.footer.col3.link.register': 'Register',
'newcasino.footer.col3.title': 'Numbers Game',
'newcasino.footer.col4.link.affiliate': 'Affiliates',
'newcasino.footer.col4.link.betting': 'Betting Rules',
'newcasino.footer.col4.link.blog': 'Blog',
'newcasino.footer.col4.link.bonusterms': 'Bonus Terms',
'newcasino.footer.col4.link.faq': 'FAQ',
'newcasino.footer.col4.link.privacy': 'Privacy Policy',
'newcasino.footer.col4.link.terms': 'Terms and Conditions',
'newcasino.footer.col4.title': 'Important links',
'newcasino.footer.copy.line1': 'Numbers Games is owned by GAC Group Limited and operated under the Kahnawake Gaming License of GAC Group Limited, a company incorporated in the Isle of Man.',
'newcasino.footer.copy.line2': '© Copyright 2024 Numbers Game. All rights reserved.',
'newcasino.game.popup.BET_TOO_HIGH': 'Bid Refused!, The maximum bet for this game is {MAXBET}.',
'newcasino.game.popup.DEPOSIT_LIMIT_1000': 'With this amount, you exceed your daily deposit limit',
'newcasino.game.popup.DEPOSIT_LIMIT_1001': 'With this amount, you exceed your weekly deposit limit',
'newcasino.game.popup.DEPOSIT_LIMIT_1002': 'With this amount, you exceed your monthly deposit limit',
'newcasino.game.popup.EXTRA_MONEY_ON_DEPOSIT': 'Congratulation!!! You just won {AMOUNT} more on your deposit.',
'newcasino.game.popup.LOST_LIMIT_REACHED': 'Bid Refused!, With this bid you will exceed your loss limit. Play with caution.',
'newcasino.game.popup.MAINTENANCE': 'The Casino is in maintenance mode, come back later to continue your game',
'newcasino.game.popup.STREAMER_WAGER_WIN': 'Congratulations! You have successfully met the wagering requirement. The balance has been transferred to your vault.',
'newcasino.game.popup.WAGER_LOSE': 'Better luck next time! You reach the minimum level of the wager balance. This wallet will be removed within 24 hours',
'newcasino.game.popup.WAGER_WIN': 'Congratulations! You have successfully met the wagering requirement. The balance has been transferred to your personal wallet.',
'newcasino.games.category.code_all_slots': 'All Slots',
'newcasino.games.category.code_baccarat': 'Baccarat',
'newcasino.games.category.code_blackjack': 'BlackJack',
'newcasino.games.category.code_buy_feature': 'Buy Feature',
'newcasino.games.category.code_crash': 'Crash Games',
'newcasino.games.category.code_game_shows': 'Game Shows',
'newcasino.games.category.code_instant_win': 'Instant Win',
'newcasino.games.category.code_live_casino': 'Live Casino',
'newcasino.games.category.code_lottery': 'Lottery',
'newcasino.games.category.code_mega_slots': 'Mega Slots',
'newcasino.games.category.code_more_games': 'More Games',
'newcasino.games.category.code_multiplayer': 'Multiplayer',
'newcasino.games.category.code_our_selection': 'Our Selection',
'newcasino.games.category.code_roulette': 'Roulette',
'newcasino.games.category.code_scratch_card': 'Scratch Card',
'newcasino.games.category.code_slots': 'Slots',
'newcasino.games.category.code_tables_cards': 'Tables & Cards',
'newcasino.games.category.code_top_favorites': 'Top Favorites',
'newcasino.games.category.code_top_games': 'Top Games',
'newcasino.games.category.code_top_live': 'Top Live',
'newcasino.games.category.code_video': 'Video',
'newcasino.games.category.code_video_bingo_keno': 'Video Bingo & Keno',
'newcasino.games.category.code_virtual_sports': 'Virtual Sports',
'newcasino.games.category.code_win_or_crash': 'Win or Crash',
'newcasino.games.newgame': 'New Games',
'newcasino.games.subtype.code_baccara': 'Baccarat',
'newcasino.games.subtype.code_baccarat': 'Baccarat',
'newcasino.games.subtype.code_blackjack': 'BlackJack',
'newcasino.games.subtype.code_craps': 'Craps',
'newcasino.games.subtype.code_crash': 'Crash Games',
'newcasino.games.subtype.code_live': 'Live Games',
'newcasino.games.subtype.code_others': 'Others',
'newcasino.games.subtype.code_roulette': 'Roulette',
'newcasino.games.subtype.code_slot': 'Slots',
'newcasino.games.type.code_casino': 'Casino',
'newcasino.games.type.code_livedealer': 'Live Dealers',
'newcasino.general.false': 'False',
'newcasino.general.true': 'True',
'newcasino.generic.error.msg': 'Something wrong happens, please contact support',
'newcasino.giveaways.admin.none': 'Their is no pending giveaway',
'newcasino.giveaways.admin.save': 'Save',
'newcasino.giveaways.admin.title': "Streamer's giveaways",
'newcasino.giveaways.amount.hint': 'Amount',
'newcasino.giveaways.amount.label': 'Amount to give',
'newcasino.giveaways.email.error': 'Invalid Email',
'newcasino.giveaways.email.hint': 'Type Email here',
'newcasino.giveaways.email.label': 'Follower Email',
'newcasino.giveaways.failure.amount': 'Your giveaway balance is not enough',
'newcasino.giveaways.failure.email': 'The Follower Email is invalid',
'newcasino.giveaways.failure.wager': 'It seems you already got your deal today! ',
'newcasino.giveaways.success.message': 'The giveaway request is done',
'newcasino.giveaways.success.wager': 'The new wager wallet is now available',
'newcasino.header.home': 'NUMBERS GAME - CASINO',
'newcasino.homegame.freespin': 'Freespins',
'newcasino.homegame.freespin.sublabel': 'You have {QTE} freespins that you can use on one of these games.',
'newcasino.homegame.live': 'Live Casino',
'newcasino.homegame.lobby': 'Lobby',
'newcasino.homegame.providers': 'Providers',
'newcasino.homegame.search': 'Search Casino',
'newcasino.homegame.showall': 'Show All',
'newcasino.homegame.slots': 'Slots',
'newcasino.info.title': 'Info',
'newcasino.loyalty.claim.success.freespin': 'You just won {QTE} Free Spins. On the main page, when your real money wallet is selected, you will see a freespins tab showing all the games where you can use them.',
'newcasino.loyalty.claim.success.loyalty': 'You just won {QTE} PNG Points',
'newcasino.loyalty.claim.success.money': 'You just won {AMOUNT}',
'newcasino.loyalty.claim.success.none': 'The box is empty, better luck tomorrow',
'newcasino.loyalty.claim.success.wager': 'You just won a bonus wager wallet: {AMOUNT} {X}x - Maximum Win: {MAXWIN}',
'newcasino.loyalty.daily.continue': 'To continue opening daily gift next month, you will have to get the minimum',
'newcasino.loyalty.daily.enable': 'PNG points to enable this feature',
'newcasino.loyalty.daily.first': 'The first month is open to everybody. You can pickup daily gift starting now by clicking it.',
'newcasino.loyalty.daily.minimum': 'You need to reach the minimum',
'newcasino.loyalty.daily.na': 'Your daily gift reward is not available this month.',
'newcasino.loyalty.daily.nextin': 'Next Gift available in:',
'newcasino.loyalty.daily.no.kyc': 'You must submit your Identity Documents in the « Edit Profile » and be approved to start getting your daily gifts.',
'newcasino.loyalty.daily.points': 'points',
'newcasino.loyalty.daily.timeleft': 'Time left to Claim:',
'newcasino.loyalty.daily.title': 'Daily Consecutive Login Reward',
'newcasino.loyalty.day1': 'Day 1',
'newcasino.loyalty.day2': 'Day 2',
'newcasino.loyalty.day3': 'Day 3',
'newcasino.loyalty.day4': 'Day 4',
'newcasino.loyalty.day5': 'Day 5',
'newcasino.loyalty.day6': 'Day 6',
'newcasino.loyalty.day7': 'Day 7',
'newcasino.loyalty.gift.confirmation.title': 'Congratulations !!!',
'newcasino.loyalty.how': 'How does it work?',
'newcasino.loyalty.how.para.1.title': 'PNG Reward Points',
'newcasino.loyalty.how.para.1.txt': 'You can exchange your points for Real Money, Free Spins or even Real Money with a wager requirement.',
'newcasino.loyalty.how.para.2.title': 'Progress Points',
'newcasino.loyalty.how.para.2.txt': 'Progress points determine the Numbers Game VIP Level you are in.',
'newcasino.loyalty.how.para.3.title': 'PNG Point Shop',
'newcasino.loyalty.how.para.3.txt': 'You can trade your PNG Reward Points here for the option that fits you best: Real Money, Free Spins, or Real Money with a wager requirement.',
'newcasino.loyalty.how.para.3.txtextra': '*If you exchange your PNG Reward Points for real money with a wager requirement, please note that the wagering requirement decreases by 5X at each new VIP level for the same price.',
'newcasino.loyalty.how.para.4.title': 'Numbers Game VIP Levels',
'newcasino.loyalty.how.para.4.txt': 'As you ascend through each level, your rewards will elevate, offering you increasingly appealing prizes with every step of your journey.',
'newcasino.loyalty.how.para.5.title': 'Daily Consecutive Visit Rewards',
'newcasino.loyalty.how.para.5.txt': 'By earning a minimum of 50 Progress Points each month, you unlock your daily consecutive visit rewards for the following month. With each passing day and as you advance through the VIP Levels, superior rewards will be available to you. The more you play, the better the level of prizes.',
'newcasino.loyalty.how.para.6.table.head.1': 'For every 5 EUR Wagered',
'newcasino.loyalty.how.para.6.table.head.2': 'PNG Reward Points',
'newcasino.loyalty.how.para.6.table.head.3': 'Progress Points',
'newcasino.loyalty.how.para.6.table.value.1': 'Slots',
'newcasino.loyalty.how.para.6.table.value.2': '1',
'newcasino.loyalty.how.para.6.table.value.3': '1',
'newcasino.loyalty.how.para.6.title': 'How to Earn',
'newcasino.loyalty.how.para.7.table.head.1': 'For every 50 EUR Wagered',
'newcasino.loyalty.how.para.7.table.head.2': 'PNG Reward Points',
'newcasino.loyalty.how.para.7.table.head.3': 'Progress Points',
'newcasino.loyalty.how.para.7.table.value.1': 'Live Dealer Games',
'newcasino.loyalty.how.para.7.table.value.2': '1',
'newcasino.loyalty.how.para.7.table.value.3': '1',
'newcasino.loyalty.how.para.7.table.value.4': 'Table Games',
'newcasino.loyalty.how.para.7.table.value.5': '1',
'newcasino.loyalty.how.para.7.table.value.6': '1',
'newcasino.loyalty.how.para.7.table.value.7': 'Crash Games',
'newcasino.loyalty.how.para.7.table.value.8': '1',
'newcasino.loyalty.how.para.7.table.value.9': '1',
'newcasino.loyalty.how.title': 'How does it works?',
'newcasino.loyalty.level.1': 'Basic Level',
'newcasino.loyalty.level.1.color': '#E6824E',
'newcasino.loyalty.level.10': 'Level IX',
'newcasino.loyalty.level.10.color': '#FFD700',
'newcasino.loyalty.level.11': 'Level X',
'newcasino.loyalty.level.11.color': '#E5E4E2',
'newcasino.loyalty.level.2': 'Level I',
'newcasino.loyalty.level.2.color': '#A1BFC2',
'newcasino.loyalty.level.3': 'Level II',
'newcasino.loyalty.level.3.color': '#EACD5B',
'newcasino.loyalty.level.4': 'Level III',
'newcasino.loyalty.level.4.color': '#C3A7D0',
'newcasino.loyalty.level.5': 'Level IV',
'newcasino.loyalty.level.5.color': '#F5F5F5',
'newcasino.loyalty.level.6': 'Level V',
'newcasino.loyalty.level.6.color': '#EB0BAC',
'newcasino.loyalty.level.7': 'Level VI',
'newcasino.loyalty.level.7.color': '#b87333',
'newcasino.loyalty.level.8': 'Level VII',
'newcasino.loyalty.level.8.color': '#b08d57',
'newcasino.loyalty.level.9': 'Level VIII',
'newcasino.loyalty.level.9.color': '#C0C0C0',
'newcasino.loyalty.level.title': "Numbers Game's VIP Levels",
'newcasino.loyalty.mylevel': 'My Level',
'newcasino.loyalty.mypoint': 'My Points',
'newcasino.loyalty.no.points': "You don't have enought point left to buy anything",
'newcasino.loyalty.progression': "PNG Points progression to enable next month's daily rewards",
'newcasino.loyalty.progression.percent': 'Next Level Progression',
'newcasino.loyalty.shop.cancel': 'Cancel',
'newcasino.loyalty.shop.category.1': 'All',
'newcasino.loyalty.shop.category.2': 'Cash',
'newcasino.loyalty.shop.category.3': 'Wagers',
'newcasino.loyalty.shop.category.4': 'Freespins',
'newcasino.loyalty.shop.category.5': 'All I can afford',
'newcasino.loyalty.shop.confirm': 'Confirm',
'newcasino.loyalty.shop.confirm.cash': 'You just bought:',
'newcasino.loyalty.shop.confirm.freespin.1': 'You just bought',
'newcasino.loyalty.shop.confirm.freespin.2': 'freespins. On the main page, when your real money wallet is selected, you will see a freespins tab showing all the games where you can use them.',
'newcasino.loyalty.shop.confirm.freespin.usethemnow': 'Use them now',
'newcasino.loyalty.shop.confirm.wager.1': 'You just bought a wager wallet of',
'newcasino.loyalty.shop.confirm.wager.2': 'with a',
'newcasino.loyalty.shop.confirm.wager.3': 'x requirement.',
'newcasino.loyalty.shop.element.avail': 'Available',
'newcasino.loyalty.shop.element.cost': 'Cost:',
'newcasino.loyalty.shop.element.freespin': 'Freespins',
'newcasino.loyalty.shop.element.na': 'Not enough PTS',
'newcasino.loyalty.shop.element.pts': 'PTS',
'newcasino.loyalty.shop.mainpage.button': 'Shop Now!',
'newcasino.loyalty.shop.mainpage.title.1': 'Freespins',
'newcasino.loyalty.shop.mainpage.title.2': 'Cash',
'newcasino.loyalty.shop.mainpage.title.3': 'Wagers',
'newcasino.loyalty.shop.mainpage.txt.1': 'Yes!!! You can buy freespins with your PNG Points',
'newcasino.loyalty.shop.mainpage.txt.2': 'You can convert your points to real cash!!!',
'newcasino.loyalty.shop.mainpage.txt.3': 'Wager wallets are also available. The xTime factor decrease at each Loyalty Level.',
'newcasino.loyalty.shop.no.kyc': 'You must submit your Identity Documents in the « Edit Profile » and be approved to start exchanging your PNG Points.',
'newcasino.loyalty.shop.subtitle': 'Exchange your PNG Reward points for the bonus of your choice! Select a reward and click on the confirm button.',
'newcasino.loyalty.shop.title': 'PNG Reward Shop',
'newcasino.loyalty.title': 'PNG Rewards',
'newcasino.loyalty.vip.features.10percextra': '10% Extra PNG Reward PTS',
'newcasino.loyalty.vip.features.5xwb': '5x less for wager bought',
'newcasino.loyalty.vip.features.birth': 'Birthday Gift',
'newcasino.loyalty.vip.features.bonusGrowth': 'Bonus Growth',
'newcasino.loyalty.vip.features.dailyBonusReload': 'Daily Bonuses / Reload',
'newcasino.loyalty.vip.features.dr': 'Daily Rewards',
'newcasino.loyalty.vip.features.levelUpBonuses': 'Level Up Bonuses',
'newcasino.loyalty.vip.features.monthlyBonuses': 'Monthly Bonuses',
'newcasino.loyalty.vip.features.rakeback': 'Rakeback',
'newcasino.loyalty.vip.features.rs': 'PNG Reward Shop',
'newcasino.loyalty.vip.features.weeklyBonuses': 'Weekly Bonuses',
'newcasino.memorylist.next': 'Next Page',
'newcasino.memorylist.of': 'of',
'newcasino.memorylist.page': 'Page',
'newcasino.memorylist.previous': 'Previous Page',
'newcasino.menu.wallets.bonus': 'Wager',
'newcasino.menu.wallets.mywallet': 'My Money',
'newcasino.menu.wallets.streamer': 'Streamer',
'newcasino.menu.wallets.switch.ko': 'Cannot switch wallet',
'newcasino.menu.wallets.switch.ok': 'Wallet Switched',
'newcasino.menu.wallets.title': 'Your Wallets:',
'newcasino.menuAdmin.1': 'User Management',
'newcasino.menuAdmin.10': 'SMS',
'newcasino.menuAdmin.11': 'Blog',
'newcasino.menuAdmin.2': 'Contest Management',
'newcasino.menuAdmin.3': 'Orders',
'newcasino.menuAdmin.4': 'Streamers',
'newcasino.menuAdmin.5': 'Giveaways',
'newcasino.menuAdmin.6': 'Games',
'newcasino.menuAdmin.7': 'Popup Management',
'newcasino.menuAdmin.8': 'Maintenance',
'newcasino.menuAdmin.9': 'Promotions',
'newcasino.menuAdmin.title': 'Admin Menu',
'newcasino.menuleft.3.101': 'Français',
'newcasino.menuleft.3.102': 'English',
'newcasino.menuleft.3.103': 'Português',
'newcasino.menuleft.3.2': 'Streamer',
'newcasino.menuleft.3.4': 'Responsible Gambling',
'newcasino.menuleft.3.5': 'Contest Winners',
'newcasino.menuleft.3.6': 'Refer a friend',
'newcasino.menuleft.3.7': 'Loyalty',
'newcasino.menuleft.4.1': 'Daily Gifts',
'newcasino.menuleft.4.2': 'PNG Shop',
'newcasino.menuleft.4.3': 'VIP Level',
'newcasino.menuleft.category.3': 'More',
'newcasino.menuleft.darkmode': 'Dark Mode',
'newcasino.menutop.button.1': 'Login',
'newcasino.menutop.button.2': 'Register',
'newcasino.menutop.button.bank': '$ --',
'newcasino.menutop.button.cashier': 'Cashier',
'newcasino.menutop.lang.en': 'English',
'newcasino.menutop.lang.fr': 'Français',
'newcasino.menutop.lang.pt': 'Português',
'newcasino.menutop.lang.title': 'Language',
'newcasino.menutop.maximize': 'Fullscreen',
'newcasino.menutop.wallet': 'Choose your wallet',
'newcasino.mobileBottomMenu.element.1': 'Menu',
'newcasino.mobileBottomMenu.element.2': 'Home',
'newcasino.mobileBottomMenu.element.3': 'Slots',
'newcasino.mobileBottomMenu.element.4': 'Live Casino',
'newcasino.mobileBottomMenu.element.5': 'Live Chat',
'newcasino.modal.msg.function.not.available.yet': 'This Function is not available yet',
'newcasino.modal.msg.info': 'Information',
'newcasino.player.use.freespin.button.no': 'No',
'newcasino.player.use.freespin.button.yes': 'Yes',
'newcasino.player.use.freespin.title': 'FreeSpins Available',
'newcasino.player.use.freespin.txt1': 'You have',
'newcasino.player.use.freespin.txt2': 'freespins available. Do you want to use them on this game?',
'newcasino.popups.admin.detail.active': 'Active',
'newcasino.popups.admin.detail.body.en': 'Content',
'newcasino.popups.admin.detail.body.en.hint': 'Type the content in English',
'newcasino.popups.admin.detail.body.fr': 'Content',
'newcasino.popups.admin.detail.body.fr.hint': 'Type the content in French',
'newcasino.popups.admin.detail.body.pt': 'Content',
'newcasino.popups.admin.detail.body.pt.hint': 'Type the content in Portuguese',
'newcasino.popups.admin.detail.button.delete': 'Delete',
'newcasino.popups.admin.detail.button.save': 'Save',
'newcasino.popups.admin.detail.button.test': 'Test it',
'newcasino.popups.admin.detail.date.end': 'End Date',
'newcasino.popups.admin.detail.date.end.hint': 'Ex: dd/mm/yyyy',
'newcasino.popups.admin.detail.date.start': 'Start Date',
'newcasino.popups.admin.detail.date.start.hint': 'Ex: dd/mm/yyyy',
'newcasino.popups.admin.detail.header.title.missing': '???',
'newcasino.popups.admin.detail.header.title.popup': 'Popup Editor',
'newcasino.popups.admin.detail.height': 'Popup Height (px)',
'newcasino.popups.admin.detail.height.hint': 'Height (default 100%)',
'newcasino.popups.admin.detail.html': 'Use Html',
'newcasino.popups.admin.detail.internal.name': 'Internal Name',
'newcasino.popups.admin.detail.internal.name.hint': 'Type the Internal Name',
'newcasino.popups.admin.detail.receiver.admin': 'Admin',
'newcasino.popups.admin.detail.receiver.affiliates': 'Affiliates',
'newcasino.popups.admin.detail.receiver.all': 'Everybody',
'newcasino.popups.admin.detail.receiver.hint': 'Select receiver...',
'newcasino.popups.admin.detail.receiver.label': 'Receiver',
'newcasino.popups.admin.detail.receiver.logged': 'All logged users',
'newcasino.popups.admin.detail.receiver.partners': 'Partners',
'newcasino.popups.admin.detail.receiver.players': 'Players',
'newcasino.popups.admin.detail.receiver.streamers': 'Streamers',
'newcasino.popups.admin.detail.tab.english': 'English',
'newcasino.popups.admin.detail.tab.french': 'French',
'newcasino.popups.admin.detail.tab.portuguese': 'Portuguese',
'newcasino.popups.admin.detail.title.en': 'Title English',
'newcasino.popups.admin.detail.title.en.hint': 'Type the title in English',
'newcasino.popups.admin.detail.title.fr': 'Title French',
'newcasino.popups.admin.detail.title.fr.hint': 'Type the title in French',
'newcasino.popups.admin.detail.title.pt': 'Title Portuguese',
'newcasino.popups.admin.detail.title.pt.hint': 'Type the title in Portuguese',
'newcasino.popups.admin.list.add.button': 'New Popup',
'newcasino.popups.admin.list.col1': 'Creation Date',
'newcasino.popups.admin.list.col2': 'Name',
'newcasino.popups.admin.list.col3': 'Active',
'newcasino.popups.admin.list.no': 'No',
'newcasino.popups.admin.list.title': 'Popups management',
'newcasino.popups.admin.list.yes': 'Yes',
'newcasino.profile.info.address.address.error': 'Your address is too short',
'newcasino.profile.info.address.address.hint': 'Type your address here',
'newcasino.profile.info.address.address.label': 'Address',
'newcasino.profile.info.address.city.error': 'Your city is too short',
'newcasino.profile.info.address.city.hint': 'Type your city here',
'newcasino.profile.info.address.city.label': 'City',
'newcasino.profile.info.address.country.hint': 'Select your country...',
'newcasino.profile.info.address.country.label': 'Country',
'newcasino.profile.info.address.state.error': 'Your state is too short',
'newcasino.profile.info.address.state.hint': 'Type your State here',
'newcasino.profile.info.address.state.label': 'State/Province',
'newcasino.profile.info.address.zip.error': 'Your Postal Code is too short',
'newcasino.profile.info.address.zip.hint': 'Postal Code',
'newcasino.profile.info.address.zip.label': 'Postal Code',
'newcasino.profile.info.button.change': 'Change',
'newcasino.profile.info.button.update': 'Update',
'newcasino.profile.info.email.label': 'Email',
'newcasino.profile.info.fn.label': 'First Name',
'newcasino.profile.info.ln.label': 'Last Name',
'newcasino.profile.info.phone.label': 'Phone Number',
'newcasino.profile.info.success': 'Your information has been modified.',
'newcasino.profile.password.button': 'Update',
'newcasino.profile.password.doc.legacy': 'Legacy Images',
'newcasino.profile.password.doc.newimages': 'New Images',
'newcasino.profile.password.doc.proof1.approve': 'Approve',
'newcasino.profile.password.doc.proof1.decline': 'Decline',
'newcasino.profile.password.doc.proof1.desc': "Passport or Driver's License or national ID Card.",
'newcasino.profile.password.doc.proof1.title': 'Proof of identity',
'newcasino.profile.password.doc.proof2.desc': 'Utility bill or bank statement',
'newcasino.profile.password.doc.proof2.title': 'Proof of address',
'newcasino.profile.password.doc.select': 'Select Image',
'newcasino.profile.password.failure': 'Your password could not be changed, check your current password.',
'newcasino.profile.password.input1.error': 'Password too short',
'newcasino.profile.password.input1.hint': 'Type your current password here',
'newcasino.profile.password.input1.label': 'Current Password',
'newcasino.profile.password.input2.error': 'You need at less 8 characters with a mix of uppercase and lowercase',
'newcasino.profile.password.input2.hint': 'Type your new password here',
'newcasino.profile.password.input2.label': 'New Password',
'newcasino.profile.password.success': 'Your password has been changed.',
'newcasino.profile.popup.phone.button.cancel': 'Cancel',
'newcasino.profile.popup.phone.button.end': 'Validate',
'newcasino.profile.popup.phone.button.next': 'Send SMS',
'newcasino.profile.popup.phone.countrycode.hint': 'Code',
'newcasino.profile.popup.phone.countrycode.label': 'Select Country Code',
'newcasino.profile.popup.phone.number.hint': 'Phone Number',
'newcasino.profile.popup.phone.number.label': 'Phone Number',
'newcasino.profile.popup.phone.sms.failure.send': 'The SMS could not be send, check the number or contact support',
'newcasino.profile.popup.phone.sms.failure.verify': 'The SMS could not be verify. check the code or contact support',
'newcasino.profile.popup.phone.sms.success.send': 'The SMS has been send to your phone',
'newcasino.profile.popup.phone.sms.success.verify': 'Your phone number has been changed',
'newcasino.profile.popup.phone.subtitle': 'To Change your phone number, we have to validate your new phone number with an sms.',
'newcasino.profile.popup.phone.title': 'Change your phone number',
'newcasino.profile.tab.bank': 'Transactions',
'newcasino.profile.tab.docs': 'Identity Documents',
'newcasino.profile.tab.password': 'Change Password',
'newcasino.profile.tab.personal': 'Personal Information',
'newcasino.profile.tab.pngpoints': 'PNG Points',
'newcasino.profile.tab.wallets': 'Wallets',
'newcasino.profile.title': 'My Profile',
'newcasino.promotions.admin.list.add.button': 'New Promotion',
'newcasino.promotions.admin.list.col0': 'In Progress',
'newcasino.promotions.admin.list.col1': 'Promotion Name',
'newcasino.promotions.admin.list.col2': 'Active',
'newcasino.promotions.admin.list.col3': 'Start Date',
'newcasino.promotions.admin.list.col4': 'End Date',
'newcasino.promotions.admin.list.title': 'Promotions',
'newcasino.promotions.admin.prom.add.success': 'The promotion has been added',
'newcasino.promotions.admin.prom.mod.success': 'The promotion has been updated',
'newcasino.promotions.admin.prom.new.deposit.action': 'Action on deposit amount',
'newcasino.promotions.admin.prom.new.deposit.chance': 'Chance to win',
'newcasino.promotions.admin.prom.new.deposit.double': 'Double',
'newcasino.promotions.admin.prom.new.deposit.enddate': 'End Date',
'newcasino.promotions.admin.prom.new.deposit.endtime': 'End Time',
'newcasino.promotions.admin.prom.new.deposit.maxwin': 'Max Win Per Deposit (EUR)',
'newcasino.promotions.admin.prom.new.deposit.select': 'Choose one...',
'newcasino.promotions.admin.prom.new.deposit.startdate': 'Start Date',
'newcasino.promotions.admin.prom.new.deposit.starttime': 'Start Time',
'newcasino.promotions.admin.prom.new.deposit.total': 'Total Amount Gift (EUR)',
'newcasino.promotions.admin.prom.new.deposit.triple': 'Triple',
'newcasino.promotions.admin.prom.new.gift.audience.all': 'All',
'newcasino.promotions.admin.prom.new.gift.audience.select': 'Choose recipients',
'newcasino.promotions.admin.prom.new.gift.audience.specific': 'Upload user list',
'newcasino.promotions.admin.prom.new.gift.cash': 'Money',
'newcasino.promotions.admin.prom.new.gift.cash.amount': 'Amount In Euro',
'newcasino.promotions.admin.prom.new.gift.cash.quantity': 'Quantity',
'newcasino.promotions.admin.prom.new.gift.cron.date': 'Activation Date',
'newcasino.promotions.admin.prom.new.gift.cron.time': 'Activation Time',
'newcasino.promotions.admin.prom.new.gift.freespin': 'Freespins',
'newcasino.promotions.admin.prom.new.gift.freespin.expiration.days': 'Expiry in days',
'newcasino.promotions.admin.prom.new.gift.freespin.quantity': 'Quantity',
'newcasino.promotions.admin.prom.new.gift.png': 'PNG Points',
'newcasino.promotions.admin.prom.new.gift.select': 'Choose one...',
'newcasino.promotions.admin.prom.new.gift.title': 'Type of Gift',
'newcasino.promotions.admin.prom.new.gift.wager': 'Wager',
'newcasino.promotions.admin.prom.new.gift.wager.amount': 'Amount In Euro',
'newcasino.promotions.admin.prom.new.gift.wager.expiration.date': 'Expiration Date',
'newcasino.promotions.admin.prom.new.gift.wager.expiration.time': 'Expiration Time',
'newcasino.promotions.admin.prom.new.gift.wager.maxwin': 'Maximum Win',
'newcasino.promotions.admin.prom.new.gift.wager.xtime': 'X Times',
'newcasino.promotions.admin.prom.new.name': 'Promotion Name',
'newcasino.promotions.admin.prom.new.next': 'Next Step',
'newcasino.promotions.admin.prom.new.title': 'New Promotions',
'newcasino.promotions.admin.prom.new.type': 'Promotion Type',
'newcasino.promotions.admin.prom.new.type.deposit': 'Deposit Bonus',
'newcasino.promotions.admin.prom.new.type.instant': 'Instant Gift',
'newcasino.promotions.admin.prom.new.type.select': 'Choose one...',
'newcasino.promotions.admin.prom.new.upload': 'Select CSV file',
'newcasino.promotions.admin.prom.viewer.active': 'Active',
'newcasino.promotions.admin.prom.viewer.audience': 'Receivers',
'newcasino.promotions.admin.prom.viewer.audience.all': 'All Players (Except Banned)',
'newcasino.promotions.admin.prom.viewer.audience.list': 'CSV List',
'newcasino.promotions.admin.prom.viewer.button.activate': 'Activate',
'newcasino.promotions.admin.prom.viewer.button.deactivate': 'Deactivate',
'newcasino.promotions.admin.prom.viewer.button.delete': 'Delete',
'newcasino.promotions.admin.prom.viewer.button.download.original': 'Download User List',
'newcasino.promotions.admin.prom.viewer.button.download.result': 'Download Results List',
'newcasino.promotions.admin.prom.viewer.cash.nbr': 'Cash (Euro)',
'newcasino.promotions.admin.prom.viewer.cronned': 'Executed (Cron Job Passed)',
'newcasino.promotions.admin.prom.viewer.deposit.chance': 'Chance to Win (%)',
'newcasino.promotions.admin.prom.viewer.deposit.end': 'End Time (London Time)',
'newcasino.promotions.admin.prom.viewer.deposit.max': 'Max Gain Per Deposit (Euro)',
'newcasino.promotions.admin.prom.viewer.deposit.start': 'Start Time (London Time)',
'newcasino.promotions.admin.prom.viewer.deposit.stats.wineuro': 'Cash Gived',
'newcasino.promotions.admin.prom.viewer.deposit.total': 'Max Money To Give (Euro)',
'newcasino.promotions.admin.prom.viewer.freespins.expiration': 'Expiry in days',
'newcasino.promotions.admin.prom.viewer.freespins.nbr': 'FreeSpins',
'newcasino.promotions.admin.prom.viewer.gift': 'Gift Type',
'newcasino.promotions.admin.prom.viewer.png.nbr': 'PNG Points',
'newcasino.promotions.admin.prom.viewer.promo.deposit': 'Deposit Bonus',
'newcasino.promotions.admin.prom.viewer.promo.gift': 'Instant Gift',
'newcasino.promotions.admin.prom.viewer.type': 'Promotion Type',
'newcasino.promotions.admin.prom.viewer.wager.expDate': 'Wager Expiration Date',
'newcasino.promotions.admin.prom.viewer.wager.maxwin': 'Wager Max Win (Euro)',
'newcasino.promotions.admin.prom.viewer.wager.nbr': 'Wager Amount (Euro)',
'newcasino.promotions.admin.prom.viewer.wager.stats.inprogress': 'In Progress',
'newcasino.promotions.admin.prom.viewer.wager.stats.looser': 'Nbr Losers',
'newcasino.promotions.admin.prom.viewer.wager.stats.nbr': 'Wager Gived',
'newcasino.promotions.admin.prom.viewer.wager.stats.title': 'Statistics',
'newcasino.promotions.admin.prom.viewer.wager.stats.wineuro': 'Total Wins Euro',
'newcasino.promotions.admin.prom.viewer.wager.stats.winners': 'Nbr Winners',
'newcasino.promotions.admin.prom.viewer.wager.x': 'Wager xTimes',
'newcasino.referafriend.button.claim': 'Claim!',
'newcasino.referafriend.claim': 'CLAIM MY CASH',
'newcasino.referafriend.copied': 'Copied!',
'newcasino.referafriend.copy': 'Copy Link',
'newcasino.referafriend.header.amount': 'Amount',
'newcasino.referafriend.header.currency': 'Currency',
'newcasino.referafriend.header.name': 'Friend Name',
'newcasino.referafriend.info.part1': 'Earn {amountEuro} Euros ({localCurrency}) for each successful invite. When your friend plays through their first deposit {wagerRequired}, you will be credited {amountEuro} Euro to your cash balance. Yes, you can withdraw the money at any time',
'newcasino.referafriend.info.part1-amountEuro': '17',
'newcasino.referafriend.info.part1-minimalDeposit': '$10',
'newcasino.referafriend.info.part1-wagerRequired': '1x',
'newcasino.referafriend.info.part1-wagerRequiredRecipient': '35x',
'newcasino.referafriend.info.part2': 'Your friend will have the opportunity to choose his welcome offer.',
'newcasino.referafriend.info.part3': 'To claim the cash, simply click on "Claim my cash" right here on the Refer-a-Friend page.',
'newcasino.referafriend.invite': 'Invite your {friends}',
'newcasino.referafriend.invite.part1': 'friends',
'newcasino.referafriend.money.claimed': 'Prizes you already claimed',
'newcasino.referafriend.money.to.claim': 'Prize(s) available',
'newcasino.referafriend.nbr.player.deposit': 'Friends who deposit money',
'newcasino.referafriend.nbr.player.use.link': 'Friends who register with your link',
'newcasino.referafriend.nbr.player.wager': 'Friends who entitle you cash',
'newcasino.referafriend.popup.close': 'Cancel',
'newcasino.referafriend.popup.text': 'Select the Amount(s) you wish to use. If the amounts are in foreign currency they will be automatically converted',
'newcasino.referafriend.popup.title': 'Claim my Cash',
'newcasino.referafriend.popup.wait': 'Please Wait...',
'newcasino.referafriend.title': 'Refer-a-friend',
'newcasino.referafriend.wager.success': 'Amazing!!! You just got {money}.',
'newcasino.reset.password.failure': 'Your password cannot be reset, this link might be expired',
'newcasino.reset.password.password.label': 'New Password',
'newcasino.reset.password.success': 'Your password has been reset',
'newcasino.reset.password.title': 'Reset your password',
'newcasino.rg.activity.check.page.120': '120 Minutes',
'newcasino.rg.activity.check.page.30': '30 Minutes',
'newcasino.rg.activity.check.page.60': '60 Minutes',
'newcasino.rg.activity.check.page.checkbox': "By clicking here I understand that I'm setting up an automated activity check",
'newcasino.rg.activity.check.page.subtitle': 'WE WANT YOU TO ENJOY PLAYING RESPONSIBLY ON OUR CASINO, WHICH IS WHY WE PROVIDE AN ACTIVITY CHECK FEATURE. IT WILL HELP YOU KEEP TRACK OF YOUR PLAYING TIME BY SENDING ADJUSTABLE NOTIFICATIONS THAT ACT AS YOUR PLAYER ALARM CLOCK, STARTING FROM THE MOMENT YOU LOG IN.',
'newcasino.rg.activity.check.page.title': 'Activity Check',
'newcasino.rg.activity.check.success.1': 'From now on, you will received an alert after ',
'newcasino.rg.activity.check.success.2': 'minutes of playing',
'newcasino.rg.deposit.limit.page.button': 'Set/Update',
'newcasino.rg.deposit.limit.page.daily': 'Your current Daily Deposit Limit is:',
'newcasino.rg.deposit.limit.page.monthly': 'Your current Monthly Deposit Limit is:',
'newcasino.rg.deposit.limit.page.subtitle': '* Values can only be changed once every 48 hours',
'newcasino.rg.deposit.limit.page.title': 'Deposit Limit',
'newcasino.rg.deposit.limit.page.weekly': 'Your current Weekly Deposit Limit is:',
'newcasino.rg.lost.limit.page.button': 'Set/Update',
'newcasino.rg.lost.limit.page.daily': 'Your current Daily Loss Limit is:',
'newcasino.rg.lost.limit.page.monthly': 'Your current Monthly Loss Limit is:',
'newcasino.rg.lost.limit.page.subtitle': '* Values can only be changed once every 48 hours',
'newcasino.rg.lost.limit.page.title': 'Loss Limit',
'newcasino.rg.lost.limit.page.weekly': 'Your current Weekly Loss Limit is:',
'newcasino.rg.self.exclusion.page.1': '1 Year',
'newcasino.rg.self.exclusion.page.3': '3 Years',
'newcasino.rg.self.exclusion.page.5': '5 Years',
'newcasino.rg.self.exclusion.page.6': '6 Months',
'newcasino.rg.self.exclusion.page.checkbox': 'By clicking here I understand that this action will block my account',
'newcasino.rg.self.exclusion.page.subtitle': 'AT NUMBERS GAME, WE BELIEVE IN RESPONSIBLE GAMBLING. IF YOU EVER FEEL LIKE YOU ARE PLAYING MORE THAN YOU CAN AFFORD, WE ENCOURAGE YOU TO TAKE A BREAK AND CONSIDER SELF-EXCLUSION. REMEMBER, RESPONSIBLE GAMBLING IS KEY TO ENJOYING ONLINE CASINOS SAFELY.',
'newcasino.rg.self.exclusion.page.title': 'Self Exclusion',
'newcasino.rg.self.exclusion.success': 'Your account is lock until : ',
'newcasino.rg.take.break.page.24': '24 Hours',
'newcasino.rg.take.break.page.30': '30 Days',
'newcasino.rg.take.break.page.7': '7 Days',
'newcasino.rg.take.break.page.checkbox': 'By clicking here I understand that this action will block my account',
'newcasino.rg.take.break.page.subtitle': 'WE PRIORITIZE RESPONSIBLE GAMBLING FOR A SAFE AND ENJOYABLE ONLINE CASINO EXPERIENCE. IF YOU NEED A SHORT BREAK FROM GAMBLING, OUR "TAKE A BREAK" FEATURE ALLOWS YOU TO TEMPORARILY SUSPEND YOUR ACCOUNT. IF YOU REQUIRE A LONGER BREAK, DO NOT HESITATE TO CHECK OUR SELF-EXCLUSION FEATURE.',
'newcasino.rg.take.break.page.title': 'Take a break',
'newcasino.rg.take.break.success': 'Your account is lock until : ',
'newcasino.search.noresult': 'No result found.',
'newcasino.search.result.title1': 'Search Results -',
'newcasino.search.result.title2': 'item(s)',
'newcasino.session.fourOone': "Your don't have access to this page. Please login again.",
'newcasino.session.fourOone.title': 'Error',
'newcasino.session.inactive.msg': 'Your session has timed out. Please login.',
'newcasino.session.inactive.title': 'Session timeout',
'newcasino.session.inactivity': 'Your session is expired for inactivity. Your security is our concern, please log again.',
'newcasino.session.inactivity.title': 'Session Expired',
'newcasino.session.maintenance': "The Website is currently in maintenance, It won't be long, come back in a few moments.",
'newcasino.session.maintenance.title': 'Upgrade in progress',
'newcasino.sms.admin.detail.button.send': 'Send',
'newcasino.sms.admin.detail.header.title.missing': '???',
'newcasino.sms.admin.detail.msg.en': 'English Message',
'newcasino.sms.admin.detail.msg.en.hint': 'Type the message in English',
'newcasino.sms.admin.detail.msg.fr': 'French Message',
'newcasino.sms.admin.detail.msg.fr.hint': 'Type the message in French',
'newcasino.sms.admin.detail.title.en': 'Description',
'newcasino.sms.admin.detail.title.en.hint': 'Type the description in English',
'newcasino.sms.admin.detail.twilio': 'Twilio Response',
'newcasino.sms.admin.list.add.button': 'New Message',
'newcasino.sms.admin.list.col1': 'Date',
'newcasino.sms.admin.list.col2': 'Name',
'newcasino.sms.admin.list.col3': 'Send',
'newcasino.sms.admin.list.no': 'No',
'newcasino.sms.admin.list.title': 'Text Messaging',
'newcasino.sms.admin.list.yes': 'Yes',
'newcasino.sms.fetching.data': 'Error trying to get Text messages information',
'newcasino.sms.send.fail': 'Error trying to send the Text Message',
'newcasino.sms.send.ok': 'Text Message Sended',
'newcasino.store.elements.cash': 'Cash',
'newcasino.store.elements.freespin': 'FreeSpin',
'newcasino.store.elements.wager': 'Wager',
'newcasino.streamer.claim': 'Get a wager',
'newcasino.streamer.copied': 'Copied',
'newcasino.streamer.copy': 'Copy link',
'newcasino.streamer.deal': 'Your deal: {amount} - {xTime}x, {perWeek} per week',
'newcasino.streamer.giveaway': 'Gift for your followers: Available: {availableGift}',
'newcasino.streamer.info.part1': 'Hello Streamer, Here you will find all the tools you need to give your followers the best experience.',
'newcasino.streamer.invite': 'Invite your {viewers}',
'newcasino.streamer.invite.part1': 'followers',
'newcasino.streamer.money.to.claim': 'Available Deals',
'newcasino.streamer.pending': 'Pending for approval',
'newcasino.streamer.title': 'Streamer',
'newcasino.streamer.vault': 'Your Vault',
'newcasino.streamer.vault.amount': 'Your vault contains',
'newcasino.success.delete.blog': 'Article deleted',
'newcasino.success.delete.contest': 'Contest deleted',
'newcasino.success.delete.popup': 'Popup deleted',
'newcasino.success.save.blog': 'Article saved',
'newcasino.success.save.contest': 'Contest saved',
'newcasino.success.save.popup': 'Popup saved',
'newcasino.success.title': 'Success',
'newcasino.user.address.address.fn.error': 'The first name is too short',
'newcasino.user.address.address.fn.hint': 'Type First Name',
'newcasino.user.address.address.fn.label': 'First Name',
'newcasino.user.address.address.ln.error': 'The last name is too short',
'newcasino.user.address.address.ln.hint': 'Type Last Name',
'newcasino.user.address.address.ln.label': 'Last Name',
'newcasino.user.address.kyc.approved': 'Approved',
'newcasino.user.address.kyc.nophoto': 'No Photo',
'newcasino.user.address.kyc.pending': 'Pending',
'newcasino.user.address.kyc.rejected': 'Rejected',
'newcasino.user.address.kyc.unknown': 'Unknown',
'newcasino.user.address.phonestatus.empty': 'No Phone',
'newcasino.user.address.phonestatus.failed3x': 'Failed 3x',
'newcasino.user.address.phonestatus.unknown': 'Unknown',
'newcasino.user.address.phonestatus.verif': 'Verified',
'newcasino.user.address.ustatus.active': 'Active',
'newcasino.user.address.ustatus.inactive': 'Inactive',
'newcasino.user.address.ustatus.unknown': 'Unknown',
'newcasino.user.address.utype.admin': 'Admin',
'newcasino.user.address.utype.partner': 'Partner',
'newcasino.user.address.utype.player': 'Player',
'newcasino.user.address.utype.streamer': 'Streamer',
'newcasino.user.address.utype.unknown': 'Unknown',
'newcasino.usermenu.deposit': 'Deposit / Withdraw',
'newcasino.usermenu.logout': 'Logout',
'newcasino.usermenu.profile': 'Edit Profile',
'newcasino.wallet.tx.type.0': 'Wallet Creation',
'newcasino.wallet.tx.type.100': 'Deposit - Manual',
'newcasino.wallet.tx.type.101': 'Deposit - Bank',
'newcasino.wallet.tx.type.102': 'Deposit - Withdraw Rollback',
'newcasino.wallet.tx.type.103': 'Deposit - PNG Points Exchange',
'newcasino.wallet.tx.type.104': 'Deposit - Promo Bonus',
'newcasino.wallet.tx.type.105': 'Deposit - Promo Bonus ',
'newcasino.wallet.tx.type.106': 'Deposit - RAF Bonus',
'newcasino.wallet.tx.type.110': 'Deposit - Win',
'newcasino.wallet.tx.type.115': 'Deposit - Giveaway',
'newcasino.wallet.tx.type.120': 'Deposit - Wager Win',
'newcasino.wallet.tx.type.125': 'Deposit - Wager Creation',
'newcasino.wallet.tx.type.130': 'Deposit - Bet Rollback',
'newcasino.wallet.tx.type.140': 'Deposit - System Migration',
'newcasino.wallet.tx.type.150': 'Deposit - Streamer Paid',
'newcasino.wallet.tx.type.200': 'Withdraw - Manual',
'newcasino.wallet.tx.type.201': 'Withdraw - Bank',
'newcasino.wallet.tx.type.202': 'Withdraw - To Wager',
'newcasino.wallet.tx.type.210': 'Withdraw - Bet',
'newcasino.wallet.tx.type.230': 'Withdraw - Win Rollback',
'newcasino.wallet.tx.type.300': 'Wallet Closed - Money Transfered',
'raf.wager.newcasino.menuleft.3.6': 'Refer a friend (Wager)',
'raf.wager.newcasino.newcasino.referafriend.money.to.claim': 'Wagers Available',
'raf.wager.newcasino.referafriend.claim': 'CLAIM MY WAGERS',
'raf.wager.newcasino.referafriend.info.part1': 'Receive a Matching Deposit Bonus for each successful invite. When your friend deposit a minimum of {minimalDeposit} and plays {wagerRequired} his first deposit, you get the same amount with a wager requirement of {wagerRequiredRecipient}. Refer as many friends as you like - No limit. Share the fun and reap the rewards today!',
'raf.wager.newcasino.referafriend.info.part3': 'To claim the Matching Deposit Bonus, you simply need to click on "Claim my Wager" right here on the Refer-a-Friend page.',
'raf.wager.newcasino.referafriend.money.claimed': 'Wagers you already claimed',
'raf.wager.newcasino.referafriend.nbr.player.wager': 'Friends who entitle you a Wager',
'raf.wager.newcasino.referafriend.popup.text': 'Select the Wager(s) you wish to use. If the amounts are in foreign currency they will be automatically converted',
'raf.wager.newcasino.referafriend.popup.title': 'Claim my Wagers',
'raf.wager.newcasino.referafriend.wager.success': 'Amazing!!! You just got {money} with a wager amount of {wager}.',
}

export default {
locale: 'en-US',
localeAntd,
messages
}
