/* eslint-disable */
import React, { useState } from 'react';
import style from './style.module.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import { setTheme } from 'newui/Utils/themeUtils';
import { getImageUrl } from 'newui/Utils/uiUtil';
import ApiPersistence from 'newui/Utils/ApiPersistence';
import { useIntl } from 'react-intl';
import { momentToFullText } from 'newui/Utils/dateUtil';
import moment from 'moment'
import 'moment/locale/fr'  // without this line it didn't work
import 'moment/locale/pt'  // without this line it didn't work

function BlogElement(props) {

    const intl = useIntl()
    const textPreviewLength = 100

    const [object] = useState(props.data)
    const [showAllText, setShowAllText] = useState(false);

    function getTextForLanguage() {
        let lang = ApiPersistence.getInstance().getLanguageCode();

        let text = object.textEn;

        if (lang === 'fr' && object.textFr.trim().length > 0) {
            text = object.textFr
        } else if (lang === 'pt' && object.textPt.trim().length > 0) {
            text = object.textPt
        }

        return getMinimalText(text)
    }

    function getCategoryForLanguage() {
        if (object.category === 0) {
            return intl.formatMessage({ id: 'newcasino.blog.category.general', defaultMessage: 'General' })
        }
        else if (object.category === 1) {
            return intl.formatMessage({ id: 'newcasino.blog.category.game', defaultMessage: 'Game Reviews' })
        }
        else if (object.category === 2) {
            return intl.formatMessage({ id: 'newcasino.blog.category.casino', defaultMessage: 'Casino' })
        }
        else if (object.category === 3) {
            return intl.formatMessage({ id: 'newcasino.blog.category.live', defaultMessage: 'Live Dealers' })
        }
        else if (object.category === 4) {
            return intl.formatMessage({ id: 'newcasino.blog.category.sport', defaultMessage: 'Sports Betting' })
        }
        return object.category;
    }


    function getTitleForLanguage() {
        let lang = ApiPersistence.getInstance().getLanguageCode();

        let text = object.titleEn;

        if (lang === 'fr' && object.titleFr.trim().length > 0) {
            text = object.titleFr
        } else if (lang === 'pt' && object.titlePt.trim().length > 0) {
            text = object.titlePt
        }

        return text

    }

    function getDate() {
        let toReturn = '';
        try {
            toReturn = momentToFullText(moment(object.creationDate));
        } catch (error) {
            console.error(error.message)
        }
        return toReturn;

    }


    function getMinimalText(text) {
        let toReturn = text;

        if (!showAllText) {
            if (text.length > (textPreviewLength + 3)) {
                toReturn = text.substring(0, textPreviewLength) + '...';
            }
        }

        return toReturn;
    }

    function hasImage() {
        return object.imageUrl && object.imageUrl.trim().length > 0
    }
    function getImageStyle() {
        if (hasImage) {
            return {
                backgroundImage: 'url("' + object.imageUrl + '")'
            }

        } else {
            return {
                display: 'none'
            }
        }

    }

    return (
        <div className={hasImage() ? style.mainDiv : style.mainDivNoImg}>
            {hasImage() &&
                <div className={style.blogImage} style={getImageStyle()} />
            }

            <div className={style.blogContent}>
                <div className={style.blogHeader}>
                    <div className={style.blogHeaderCat}>
                        {'[' + getCategoryForLanguage() + ']'}
                    </div>
                    <div className={style.blogHeaderPipe}>
                        {'|'}
                    </div>
                    <div className={style.blogHeaderDate}>
                        {getDate()}
                    </div>
                </div>
                <div>
                    <div className={style.blogTitle}>
                        {getTitleForLanguage()}
                    </div>
                </div>
                <div>
                    <div className={style.blogText}>
                        {getTextForLanguage()}
                    </div>
                </div>
                <div>
                    <div className={style.blogReadNext} onClick={() => setShowAllText(!showAllText)}>
                        <div>
                            {(!showAllText && getTextForLanguage().endsWith('...')) &&
                                'Read more'
                            }
                            {(showAllText) &&
                                'Read less'
                            }
                        </div>
                        <div className={style.portraitSeparator}>
                            <img alt='' src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.landscapeSeparator}>
                <img alt='' src={setTheme(getImageUrl('newui/themes/{THEME}/ContestWinners/bottomline.png'))} />
            </div>
        </div>
    );
}
export default BlogElement;
